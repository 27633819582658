import React, { useContext, useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import FullLayout from "../../layouts/FullLayout";

import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";

// context
import adminDataContext from "../../utils/context/AdminData";

// request
import cookie from "react-cookies";
import API from "../../utils/API";
import getMessage from "../../utils/getMessage";

const EditMyProfile = () => {
  const adminData = useContext(adminDataContext);

  const { register, handleSubmit, setValue } = useForm();

  const saveHandelar = (data) => {
    const token = cookie.load("token");
    const formData = new FormData();

    formData.append("token", token);
    formData.append("id", adminData?.id);
    formData.append("name", data?.name);
    formData.append("email", data?.email);
    formData.append("mobile", data?.mobile);

    API.post(`/admin/updateadmin`, formData)
      .then(({ data }) => {
        if (data?.code != 200) {
          getMessage("error", "يرجي ملي جميع الحقول");
        }
        if (data.code == 200) {
          getMessage("success", "تم الحفظ");
        }
      })
      .catch(() => {
        getMessage("error", "يرجي ملي جميع الحقول");
      });
  };

  useEffect(() => {
    if (!adminData?.name) return;

    setValue("name", adminData?.name);
    setValue("email", adminData?.email);
    setValue("mobile", adminData?.mobile);
  }, [adminData, setValue]);

  return (
    <FullLayout
      renderButtons={() => (
        <button
          onClick={handleSubmit(saveHandelar)}
          className="btn btn-primary"
        >
          حفظ
        </button>
      )}
      title="تعديل الملف الشخصي"
    >
      <form onSubmit={handleSubmit(saveHandelar)}>
        <Card className="shadow-sm border">
          <CardTitle tag="h4" className="border-bottom text-dark p-3 mb-0">
            البيانات الرئيسية
          </CardTitle>
          <CardBody>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <Label for="name">الاسم</Label>
                  <Form.Control id="name" {...register("name")} type="text" />
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <Label for="email">البريد الالكتروني</Label>
                  <Form.Control
                    id="email"
                    {...register("email")}
                    type="email"
                  />
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <Label for="mobile">موبايل</Label>
                  <Form.Control
                    id="mobile"
                    {...register("mobile")}
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </form>
    </FullLayout>
  );
};

export default EditMyProfile;
