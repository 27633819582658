import React, { useEffect, useMemo, useRef, useState } from "react";
import FullLayout from "../../layouts/FullLayout";

// MRT Imports
import MaterialReactTable from "material-react-table";

// Material-UI Imports
import { Box } from "@mui/material";

// Date
import moment from "moment";
import "moment/locale/ar";

import { IconButton } from "@mui/material";
import { Button, Form, Modal } from "react-bootstrap";

// Editor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// cookies
import cookie from "react-cookies";

// apis
import API from "../../utils/API";
import getMessage from "../../utils/getMessage";

const data = [
  {
    id: 1,
    name: "من نحن",
    date: "2023-02-25 15:27:40",
    key: "who_are_we",
  },
  {
    id: 2,
    name: "الخصوصية",
    date: "2023-02-25 14:27:40",
    key: "privacy",
  },
  {
    id: 3,
    name: "سياسة الاستخدام",
    date: "2023-02-25 16:00:40",
    key: "policy",
  },
];

const Pages = () => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState({ isShow: false, id: 0 });
  const [ar_description, setAr_description] = useState("");
  const [rowSelectionIdx, setRowSelectionIdx] = useState({});
  const tableInstanceRef = useRef();

  const openModal = (id) => {
    setShow({ isShow: true, id });
  };
  const closeModal = () => {
    setShow({ isShow: false, id: 0 });
    setAr_description("");
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        id: "name",
        header: "الاسم",
        size: 250,
        Cell: ({ row }) => (
          <Box className="text-end">
            <span>{row.original?.name}</span>
          </Box>
        ),
      },
      {
        accessorKey: "date",
        id: "date",
        header: "اخر تعديل",
        size: 250,
        Cell: ({ row }) => (
          <Box className="text-end">
            <span>
              {row.original?.date
                ? moment(row.original?.date).locale("ar").fromNow()
                : "ابدا"}
            </span>
          </Box>
        ),
      },
      {
        accessorKey: "edit",
        id: "edit",
        header: "تعديل",
        size: 250,
        Cell: ({ row }) => (
          <Box className="text-end">
            <IconButton onClick={() => openModal(row.original.id)}>
              <i className="bi bi-pencil-square fs-5"></i>
            </IconButton>
          </Box>
        ),
      },
    ],
    []
  );
  const getData = () => {
    const token = cookie.load("token");

    API(`/admin/pages?token=${token}`).then(({ data }) => {
      const collectData = data?.Data?.map((page) => ({
        id: page?.id,
        name: page?.title,
        date: page?.created_at,
        key: page?.slug,
        description: page?.description,
      }));

      setData(collectData);
    });
  };
  const saveHandelar = async () => {
    const token = cookie.load("token");
    const formData = new FormData();

    formData.append("token", token);
    formData.append("description_ar", ar_description);
    formData.append("description_en", ar_description);
    formData.append("id", show?.id);

    await API.post(`/admin/Updatepages`, formData)
      .then(({ data }) => {
        if (data?.code != 200) {
          getMessage("error", "يرجي ملي جميع الحقول");
        }
      })
      .catch(() => {
        getMessage("error", "يرجي ملي جميع الحقول");
      });
    getData();
    closeModal();
  };

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (show?.id) {
      setAr_description(data?.find((page) => page.id == show.id)?.description);
    }
  }, [show?.id, data]);

  return (
    <FullLayout title="الصفحات">
      <div style={{ maxHeight: 800, maxWidth: 1200, overflow: "auto" }}>
        <MaterialReactTable
          enableRowOrdering
          enableSorting={false}
          enablePagination={false}
          pagination={false}
          onRowSelectionChange={setRowSelectionIdx}
          columns={columns}
          data={data}
          state={{ rowSelection: rowSelectionIdx }}
          initialState={{ showColumnFilters: true }}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          enableColumnActions={false}
          enableBottomToolbar={false}
          enableColumnOrdering
          enableGrouping
          enablePinning
          enableRowSelection
          positionToolbarAlertBanner="bottom"
          tableInstanceRef={tableInstanceRef}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                data.splice(
                  hoveredRow.index,
                  0,
                  data.splice(draggingRow.index, 1)[0]
                );
                setData([...data]);
              }
            },
          })}
        />
      </div>

      {/* Edit Modal */}
      <Modal centered show={show.isShow} onHide={closeModal}>
        <Modal.Header>
          <Modal.Title>
            تعديل {data?.find((page) => page.id === show.id)?.name || "الصفحه"}
          </Modal.Title>

          <button className="btn-close me-auto ms-0" onClick={closeModal} />
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>الوصف</Form.Label>
            <CKEditor
              editor={ClassicEditor}
              className="ar_description"
              style={{
                height: "20em",
              }}
              config={{
                height: "20em",
              }}
              onChange={(e, editor) => {
                const data = editor.getData();
                setAr_description(data);
              }}
              data={ar_description}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="flex-center">
          <Button variant="primary" onClick={() => saveHandelar()}>
            حفظ
          </Button>

          <Button variant="danger" onClick={closeModal}>
            الغاء
          </Button>
        </Modal.Footer>
      </Modal>
    </FullLayout>
  );
};

export default Pages;
