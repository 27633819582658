import React, { useEffect, useState } from "react";
import { Card, CardText, CardTitle, Col, Row } from "reactstrap";
import FullLayout from "../../layouts/FullLayout";
import Cookies from "universal-cookie";
import API from "../../utils/API";

const cookies = new Cookies();

const Home = () => {
  const [mainData, setMainData] = useState({
    RequestsQuestions: "جاري التحميل",
    RequestsDesirableWorks: "جاري التحميل",
    communicationMessages: "جاري التحميل",
    users: "جاري التحميل",
    Sheikhs: "جاري التحميل",
  });

  useEffect(() => {
    const token = cookies.get("token");
    API(`/admin/home?token=${token}`)
      .then(({ data }) => {
        const collect = data?.Data;

        setMainData({
          RequestsQuestions: collect.faq_requests_dash,
          RequestsDesirableWorks: collect.works_requests_dash,
          communicationMessages: collect.contact_dash,
          users: collect.users_count,
          Sheikhs: collect.count_shaikhs_dash,
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <FullLayout title="الرئيسية">
      <Row>
        <Col md="6" lg="4" className="flex-grow-1">
          <Card body className="text-end">
            <div className="icon mb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                className="icon flat-color"
                data-name="Flat Color"
                viewBox="0 0 24 24"
              >
                <rect
                  width="20"
                  height="17"
                  x="2"
                  y="3"
                  fill="#009f76"
                  rx="2"
                ></rect>
                <circle cx="12" cy="15" r="7" fill="#043025"></circle>
                <path
                  d="M20 3H4a2 2 0 00-2 2v4h20V5a2 2 0 00-2-2z"
                  data-name="primary"
                ></path>
                <path
                  fill="#2ca9bc"
                  d="M16 6a1 1 0 01-1-1V3a1 1 0 012 0v2a1 1 0 01-1 1zM9 5V3a1 1 0 00-2 0v2a1 1 0 002 0zm6 8.5a2.5 2.5 0 00-2.5-2.5H11a1 1 0 000 2h1.5a.5.5 0 010 1H12a1 1 0 000 2h.5a2.5 2.5 0 002.5-2.5zm-3 3a1.5 1.5 0 101.5 1.5 1.5 1.5 0 00-1.5-1.5z"
                  data-name="secondary"
                ></path>
              </svg>
            </div>
            <CardText tag="h5" className="fw-bold">
              {mainData.RequestsQuestions}
            </CardText>

            <CardTitle className="small text-muted" tag="h5">
              طلبات اضافة أسئلة
            </CardTitle>
          </Card>
        </Col>
        <Col md="6" lg="4" className="flex-grow-1">
          <Card body className="text-end">
            <div className="icon mb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                className="icon line-color"
                data-name="Line Color"
                viewBox="0 0 24 24"
              >
                <g
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                >
                  <path
                    stroke="#000"
                    d="M7.25 20.12a9.47 9.47 0 004 .88h4.2a4 4 0 003.88-3l1.62-6.48A2 2 0 0019 9h-3a31.13 31.13 0 00.12-3.77A2.28 2.28 0 0013.82 3h-.05a2.2 2.2 0 00-2.15 1.72c-.36 1.5-.74 2.83-1.11 3.92A2 2 0 018.62 10H7.2"
                  ></path>
                  <path stroke="#009f76" d="M3 9h4v12H3z"></path>
                </g>
              </svg>
            </div>
            <CardText tag="h5" className="fw-bold">
              {mainData.RequestsDesirableWorks}
            </CardText>

            <CardTitle className="small text-muted" tag="h5">
              طلبات اضافة أعمال مستحبة
            </CardTitle>
          </Card>
        </Col>
        <Col md="6" lg="4" className="flex-grow-1">
          <Card body className="text-end">
            <div className="icon mb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                className="icon multi-color"
                viewBox="0 0 24 24"
              >
                <g strokeWidth="2">
                  <path
                    fill="#b7b7b7"
                    d="M21 8v7.73a2 2 0 01-1 .27H4a2 2 0 01-1-.27V8l9 5z"
                  ></path>
                  <path
                    fill="#009f76"
                    d="M21 6v2l-9 5-9-5V6a1 1 0 011-1h16a1 1 0 011 1z"
                  ></path>
                  <path
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 6v2l-9 5-9-5V6a1 1 0 011-1h16a1 1 0 011 1zm-9 7L3 8v10a1 1 0 001 1h16a1 1 0 001-1V8z"
                  ></path>
                </g>
              </svg>
            </div>
            <CardText tag="h5" className="fw-bold">
              {mainData.communicationMessages}
            </CardText>

            <CardTitle className="small text-muted" tag="h5">
              رسائل التواصل
            </CardTitle>
          </Card>
        </Col>
        <Col md="6" lg="4" className="flex-grow-1">
          <Card body className="text-end">
            <div className="icon mb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="-1 0 20 20"
              >
                <g fill="#009f76">
                  <path
                    d="M9 15h6a5 5 0 015 5 1 1 0 01-1 1H5a1 1 0 01-1-1 5 5 0 015-5z"
                    transform="translate(-3 -2)"
                  ></path>
                  <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M20 20h0a1 1 0 01-1 1H5a1 1 0 01-1-1h0a5 5 0 015-5h6a5 5 0 015 5zM12 3a4 4 0 104 4 4 4 0 00-4-4z"
                    transform="translate(-3 -2)"
                  ></path>
                </g>
              </svg>
            </div>
            <CardText tag="h5" className="fw-bold">
              {mainData.users}
            </CardText>

            <CardTitle className="small text-muted" tag="h5">
              المستخدمون
            </CardTitle>
          </Card>
        </Col>
        <Col md="6" lg="4" className="flex-grow-1">
          <Card body className="text-end">
            <div className="icon mb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="#009f76"
                viewBox="0 0 512 512"
              >
                <path
                  fill="#011812"
                  d="M186.182 182.109c0-38.559 31.259-69.818 69.818-69.818s69.818 31.259 69.818 69.818V256H186.182v-73.891z"
                ></path>
                <path
                  fill="#004231"
                  d="M151.273 217.018l16.872 252.509h175.71l16.872-252.509z"
                ></path>
                <circle cx="256" cy="48.291" r="40.727"></circle>
                <path
                  fill="#4C5870"
                  d="M256 147.479c-6.427 0-11.636 5.211-11.636 11.636v57.903h23.273v-57.903c-.001-6.425-5.211-11.636-11.637-11.636z"
                ></path>
                <path
                  fill="#A2A9AF"
                  d="M395.636 228.655H116.364c-6.427 0-11.636-5.211-11.636-11.636s5.21-11.636 11.636-11.636h279.273c6.426 0 11.636 5.211 11.636 11.636s-5.211 11.636-11.637 11.636z"
                ></path>
                <g fill="#181818">
                  <circle cx="349.091" cy="370.618" r="40.727"></circle>
                  <path d="M349.091 434.618c-31.429 0-58.007 20.766-66.762 49.325l43.49 20.493h46.545l43.49-20.493c-8.756-28.559-35.334-49.325-66.763-49.325z"></path>
                  <circle cx="162.909" cy="370.618" r="40.727"></circle>
                  <path d="M162.909 434.618c-31.429 0-58.007 20.766-66.762 49.325l43.49 20.493h46.545l43.49-20.493c-8.756-28.559-35.334-49.325-66.763-49.325z"></path>
                </g>
                <g fill="#009f76">
                  <circle cx="256" cy="370.618" r="40.727"></circle>
                  <path d="M256 434.618c-38.559 0-69.818 31.259-69.818 69.818h139.636c0-38.559-31.259-69.818-69.818-69.818z"></path>
                  <circle cx="442.182" cy="370.618" r="40.727"></circle>
                  <path d="M442.182 434.618c-38.559 0-69.818 31.259-69.818 69.818H512c0-38.559-31.259-69.818-69.818-69.818z"></path>
                  <circle cx="69.818" cy="370.618" r="40.727"></circle>
                  <path d="M69.818 434.618C31.259 434.618 0 465.877 0 504.436h139.636c0-38.559-31.258-69.818-69.818-69.818z"></path>
                </g>
              </svg>
            </div>
            <CardText tag="h5" className="fw-bold">
              {mainData.Sheikhs}
            </CardText>

            <CardTitle className="small text-muted" tag="h5">
              المشايخ
            </CardTitle>
          </Card>
        </Col>
      </Row>
    </FullLayout>
  );
};

export default Home;
