import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

//MRT Imports
import MaterialReactTable from "material-react-table";

//Material-UI Imports
import { Box, IconButton } from "@mui/material";

import { getCheckModal } from "../../../utils/getModal";

// icons
import { MdNotInterested } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";

// components
import {
  Button,
  ButtonGroup,
  Form,
  FormGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { Label } from "reactstrap";

// import { data as collectedData } from "./makeData";
import { useForm } from "react-hook-form";

// cookies
import cookie from "react-cookies";

// apis
import API from "../../../utils/API";

import moment from "moment";
import getMessage from "../../../utils/getMessage";

const worksSectionsId = 2;

const PracticalMessagesSections = () => {
  const { register, handleSubmit, reset, setValue } = useForm();

  const [show, setShow] = useState({ isShow: false, id: 0 });
  const [data, setData] = useState([]);
  const [isBtnActive, setIsBtnActive] = useState(false);
  const [rowSelectionIdx, setRowSelectionIdx] = useState({});
  const tableInstanceRef = useRef();

  const openModal = (id) => {
    setShow({ isShow: true, id });
  };
  const closeModal = () => {
    setShow({ isShow: false, id: 0 });

    reset({
      ar_name: "",
    });
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        id: "name",
        header: "الاسم",
        size: 100,
        Cell: ({ row }) => (
          <Box className="text-end d-flex align-items-center">
            <span>{row.original?.name}</span>
          </Box>
        ),
      },
      {
        accessorKey: "status",
        id: "status",
        header: "الحالة",
        size: 50,
        Cell: ({ row }) => (
          <Box
            className={`w-fit text-center px-2 py-1 rounded-3 m-auto ${
              row.original?.status === "فعال" ? "bg-movee" : "bg-danger"
            }`}
          >
            <span className="text-white">{row.original?.status}</span>
          </Box>
        ),
      },
      {
        id: "createdAt",
        header: "انشأ بتاريخ",
        Cell: ({ row }) => (
          <Box className="text-end">
            <span>{moment(row.original?.createdAt).fromNow()}</span>
          </Box>
        ),
      },
      {
        id: "actions",
        header: "العمليات",
        Cell: ({ row }) => (
          <Box className="text-end">
            <IconButton onClick={() => openModal(row.original.id)}>
              <i className="bi bi-pencil-square fs-5"></i>
            </IconButton>
          </Box>
        ),
      },
    ],
    []
  );
  const getData = () => {
    const token = cookie.load("token");

    API(`/admin/sections?token=${token}&sub_to=${worksSectionsId}`).then(
      ({ data }) => {
        const collectedData = data?.Data?.map((section) => ({
          id: section?.id,
          name: section?.name,
          status: section?.status === "active" ? "فعال" : "غير فعال",
          createdAt: section?.created_at,
          seq: section?.seq,
        }));

        // Sort the collectedData array based on the seq field
        collectedData.sort((a, b) => a.seq - b.seq);

        setData(collectedData);
      }
    );
  };

  const getRowsIds = useCallback(() => {
    const rowSelectionIds = Object.keys(rowSelectionIdx || {}).map((idx) => {
      return data[idx]?.id;
    });

    return rowSelectionIds;
  }, [data, rowSelectionIdx]);
  const saveOrder = async () => {
    const token = cookie.load("token");
    const dataWithSeq = data?.map((item, idx) => ({ ...item, seq: idx + 1 }));
    console.log(dataWithSeq);
    try {
      await API.post(`/admin/reorderNestedData?token=${token}`, {
        main_table_name: "sections",
        secondary_table_name: "section_translations",
        key: "section_id",
        data: dataWithSeq,
      });
      getMessage("success", "تم حفظ الترتيب");

      getData();
    } catch (error) {
      console.error("Error saving order:", error);
      getMessage("error", "حدث خطأ أثناء حفظ الترتيب");
    } finally {
      closeModal();
    }
  };
  const deleteRows = () => {
    const rowsIds = getRowsIds();

    if (!rowsIds?.length) {
      alert("لم تحدد اي عنصر");
    }

    const deleteCallBack = async () => {
      const formData = new FormData();
      const token = cookie.load("token");

      formData.append("token", token);
      formData.append("id", rowsIds);
      formData.append("event", "delete");

      await API.post(`/admin/sections/delete`, formData);
      getData();
      setRowSelectionIdx({});
    };

    getCheckModal({
      title: "هل انت متاكد من انك تريد حذف الاقسام المحدده",
      type: "warning",
      confirmButtonText: "نعم حذف",
      isConfirmedMsg: "تم الحذف بنجاح",
      cb: deleteCallBack,
    });
  };
  const activeSelection = () => {
    const rowsIds = getRowsIds();

    if (!rowsIds?.length) {
      alert("لم تحدد اي عنصر");
    }

    const deleteCallBack = async () => {
      const formData = new FormData();
      const token = cookie.load("token");

      formData.append("token", token);
      formData.append("id", rowsIds);
      formData.append("event", "active");

      await API.post(`/admin/updateSectionsStatus`, formData);
      getData();
      setRowSelectionIdx({});
    };

    getCheckModal({
      title: "هل انت متاكد من انك تريد تفعيل الاقسام المحددين",
      type: "warning",
      confirmButtonText: "نعم تفعيل",
      isConfirmedMsg: "تم التفعيل بنجاح",
      cb: deleteCallBack,
    });
  };
  const disActiveSelection = () => {
    const rowsIds = getRowsIds();

    if (!rowsIds?.length) {
      alert("لم تحدد اي عنصر");
    }

    const deleteCallBack = async () => {
      const formData = new FormData();
      const token = cookie.load("token");

      formData.append("token", token);
      formData.append("id", rowsIds);
      formData.append("event", "not_active");

      await API.post(`/admin/updateSectionsStatus`, formData);
      getData();
      setRowSelectionIdx({});
    };

    getCheckModal({
      title: "هل انت متاكد من انك تريد تعطيل الاقسام المحددين",
      type: "warning",
      confirmButtonText: "نعم تعطيل",
      isConfirmedMsg: "تم التعطيل بنجاح",
      cb: deleteCallBack,
    });
  };
  const saveHandelar = async (data) => {
    const token = cookie.load("token");
    const formData = new FormData();

    formData.append("token", token);
    formData.append("sub_to", worksSectionsId);
    formData.append("name_ar", data?.ar_name);
    show?.id && formData.append("id", show?.id);

    await API.post(
      show?.id ? "/admin/updateSections" : "/admin/createSections",
      formData
    )
      .then(({ data }) => {
        if (data?.code != 200) {
          getMessage("error", "يرجي ملي جميع الحقول");
        }
      })
      .catch(() => {
        getMessage("error", "يرجي ملي جميع الحقول");
      });
    getData();
    closeModal();
  };

  useEffect(() => {
    const rowsIds = getRowsIds();

    if (rowsIds?.length) {
      setIsBtnActive(true);
    } else {
      setIsBtnActive(false);
    }
  }, [getRowsIds, rowSelectionIdx]);

  useEffect(() => {
    getData();
  }, []);

  // get data
  useEffect(() => {
    if (show?.id) {
      const currentData = data?.find((data) => data.id === show.id);

      setValue("ar_name", currentData?.name);
    }
  }, [data, show.id, setValue]);

  return (
    <article className="mb-5">
      <h2 className="mb-3">الاقسام</h2>

      <div style={{ maxHeight: 800, maxWidth: 1200, overflow: "auto" }}>
        <MaterialReactTable
          enableRowOrdering
          enableSorting={false}
          enablePagination={false}
          pagination={false}
          onRowSelectionChange={setRowSelectionIdx}
          columns={columns}
          data={data}
          state={{ rowSelection: rowSelectionIdx }}
          initialState={{ showColumnFilters: true }}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          enableColumnActions={false}
          enableBottomToolbar={false}
          enableColumnOrdering
          enableGrouping
          enablePinning
          enableRowSelection
          positionToolbarAlertBanner="bottom"
          tableInstanceRef={tableInstanceRef}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                data.splice(
                  hoveredRow.index,
                  0,
                  data.splice(draggingRow.index, 1)[0]
                );
                setData([...data]);
              }
            },
          })}
          renderTopToolbarCustomActions={() => (
            <div className="flex-center">
              <ButtonGroup className="ms-4">
                <Button
                  onClick={activeSelection}
                  disabled={!isBtnActive}
                  variant="primary"
                  className="flex-center text-white"
                >
                  <i className="bi bi-check2 fs-5 flex-center" />
                  <span className="ms-1">فعال</span>
                </Button>

                <Button
                  onClick={disActiveSelection}
                  disabled={!isBtnActive}
                  variant="warning"
                  className="flex-center text-white"
                >
                  <MdNotInterested size={20} />
                  <span className="ms-1">غير فعال</span>
                </Button>

                <Button
                  onClick={deleteRows}
                  disabled={!isBtnActive}
                  variant="danger"
                  className="flex-center text-white"
                >
                  <i className="bi bi-trash3" />
                  <span className="ms-1">حذف</span>
                </Button>
              </ButtonGroup>

              <Button
                onClick={saveOrder}
                variant="primary"
                className="flex-center text-white ms-4"
              >
                <AiOutlinePlus size={20} />
                <span className="ms-1">حفظ الترتيب</span>
              </Button>
              <Button
                onClick={() => openModal(0)}
                variant="primary"
                className="flex-center text-white"
              >
                <AiOutlinePlus size={20} />
                <span className="ms-1">اضافه</span>
              </Button>
            </div>
          )}
        />
      </div>

      {/* Edit Modal or Add */}
      <Modal centered show={show.isShow} onHide={closeModal}>
        <Modal.Header>
          <Modal.Title>اضافة او تعديل الاقسام</Modal.Title>

          <button className="btn-close me-auto ms-0" onClick={closeModal} />
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Row className="mb-3">
              <FormGroup>
                <Label for="ar_name">الاسم</Label>
                <Form.Control
                  id="ar_name"
                  type="text"
                  {...register("ar_name")}
                />
              </FormGroup>
            </Row>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="flex-center">
          <Button variant="primary" onClick={handleSubmit(saveHandelar)}>
            حفظ
          </Button>

          <Button variant="danger" onClick={closeModal}>
            الغاء
          </Button>
        </Modal.Footer>
      </Modal>
    </article>
  );
};

export default PracticalMessagesSections;
