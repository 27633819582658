import React from "react";

// layout
import FullLayout from "../../layouts/FullLayout";

import ReferencesItems from "./ReferencesItems";
// import ReferencesSections from "./ReferencesSections";

const References = () => {
  return (
    <FullLayout title="المراجع">
      {/* <ReferencesSections /> */}
      <ReferencesItems />
    </FullLayout>
  );
};

export default References;
