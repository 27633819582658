import React from "react";

import LogoWhite from "../../assets/images/logos/monsterlogo.png";
import "../../styles/loading.scss";

const Loading = () => {
  return (
    <div className="loading-page">
      <div className="loading-page__spinner flex-center flex-column">
        <img
          width={100}
          src={LogoWhite}
          alt="Logo"
          className="loading-page__logo mb-4"
        />

        <div className="flex-center mt-4">
          <div className="loading-page__text ms-3 fs-2">جاري التحميل</div>
          <div className="dot1"></div>
          <div className="dot2"></div>
          <div className="dot3"></div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
