import React, { useState } from "react";

// layout
import FullLayout from "../../layouts/FullLayout";

import WorksItems from "./WorksItems";
import WorksSections from "./WorksSections";

const Works = () => {
  const [sections, setSections] = useState([]);

  return (
    <FullLayout title="الأعمال المستحبة">
      <WorksSections sections={sections} setSections={setSections} />
      <WorksItems sections={sections} setSections={setSections} />
    </FullLayout>
  );
};

export default Works;
