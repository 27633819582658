import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

//MRT Imports
import MaterialReactTable from "material-react-table";

//Material-UI Imports
import { Box } from "@mui/material";

import { getCheckModal } from "../../../utils/getModal";

// request
import cookie from "react-cookies";
import API from "../../../utils/API";

import moment from "moment";
import { ButtonGroup, Button } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import getMessage from "../../../utils/getMessage";
import { MdNotInterested } from "react-icons/md";

const MessagesRequests = () => {
  const [data, setData] = useState([]);
  const [isBtnActive, setIsBtnActive] = useState(false);
  const [rowSelectionIdx, setRowSelectionIdx] = useState({});
  const tableInstanceRef = useRef();

  const columns = useMemo(
    () => [
      {
        accessorKey: "description",
        id: "description",
        header: "التفاصيل",
        size: 250,
        Cell: ({ row }) => (
          <Box className="text-end d-flex align-items-center">
            <span>{row.original?.description}</span>
          </Box>
        ),
      },
      {
        accessorKey: "type",
        id: "type",
        header: "النوع",
        size: 100,
        Cell: ({ row }) => (
          <Box className="text-end d-flex align-items-center">
            <span>{row.original?.type}</span>
          </Box>
        ),
      },
      {
        accessorKey: "month",
        id: "month",
        header: "شهر",
        size: 100,
        Cell: ({ row }) => (
          <Box className="text-end d-flex align-items-center">
            <span>{row.original?.month}</span>
          </Box>
        ),
      },
      {
        accessorKey: "isNum",
        id: "isNum",
        header: "له عدد",
        size: 100,
        Cell: ({ row }) => (
          <Box className="text-end d-flex align-items-center">
            <span>{row.original?.isNum}</span>
          </Box>
        ),
      },
      {
        accessorKey: "maximum",
        id: "maximum",
        header: "	الحد الاعلى للعد",
        size: 100,
        Cell: ({ row }) => (
          <Box className="text-end d-flex align-items-center">
            <span>{row.original?.maximum}</span>
          </Box>
        ),
      },
      {
        accessorKey: "status",
        id: "status",
        header: "الحالة",
        size: 50,
        Cell: ({ row }) => (
          <Box
            className={`w-fit text-center px-2 py-1 rounded-3 m-auto ${
              row.original?.status === "فعال" ? "bg-movee" : "bg-danger"
            }`}
          >
            <span className="text-white">{row.original?.status}</span>
          </Box>
        ),
      },
      {
        id: "createdAt",
        header: "انشأ بتاريخ",
        Cell: ({ row }) => (
          <Box className="text-end">
            <span>{moment(row.original?.createdAt).fromNow()}</span>
          </Box>
        ),
      },
    ],
    []
  );
  const getRowsIds = useCallback(() => {
    const rowSelectionIds = Object.keys(rowSelectionIdx || {}).map((idx) => {
      return data[idx]?.id;
    });

    return rowSelectionIds;
  }, [data, rowSelectionIdx]);
  const saveOrder = async () => {
    const token = cookie.load("token");
    const dataWithSeq = data?.map((item, idx) => ({ ...item, seq: idx + 1 }));
    console.log(dataWithSeq);
    try {
      await API.post(`/admin/reorderData?token=${token}`, {
        table_name: "work_requests",
        data: dataWithSeq,
      });
      getMessage("success", "تم حفظ الترتيب");

      getData();
    } catch (error) {
      console.error("Error saving order:", error);
      getMessage("error", "حدث خطأ أثناء حفظ الترتيب");
    }
  };
  const deleteRows = () => {
    const rowsIds = getRowsIds();

    if (!rowsIds?.length) {
      alert("لم تحدد اي عنصر");
    }

    const deleteCallBack = async () => {
      const formData = new FormData();
      const token = cookie.load("token");

      formData.append("type", "request");
      formData.append("event", "delete");
      formData.append("token", token);
      formData.append("id", rowsIds);

      await API.post(`/admin/workstatus`, formData);
      getData();
      setRowSelectionIdx({});
    };

    getCheckModal({
      title: "هل انت متاكد من انك تريد حذف الاعمال المحدده",
      type: "warning",
      confirmButtonText: "نعم حذف",
      isConfirmedMsg: "تم الحذف بنجاح",
      cb: deleteCallBack,
    });
  };
  const getData = () => {
    const token = cookie.load("token");

    API(`/admin/works_requests?token=${token}`).then(({ data }) => {
      const collectData = data?.Data?.map((item) => ({
        id: item?.id,
        description: item?.details,
        reward: item?.reward,
        type: item?.type,
        month: item?.month,
        isNum: item?.has_counter ? "نعم" : "لا",
        maximum: item?.max_count,
        resource: item?.source,
        status: item?.status === "new" ? "جديد" : "فعال",
        sheikh: item?.sheikh,
        createdAt: item?.created_at,
        seq: item?.seq,
      }));

      collectData.sort((a, b) => a.seq - b.seq);
      setData(collectData);
    });
  };
  const activeSelection = () => {
    const rowsIds = getRowsIds();

    if (!rowsIds?.length) {
      alert("لم تحدد اي عنصر");
    }

    const deleteCallBack = async () => {
      const formData = new FormData();
      const token = cookie.load("token");

      formData.append("token", token);
      formData.append("id", rowsIds);
      formData.append("status", 1);
      formData.append("type", "request");
      formData.append("event", "approved");

      await API.post(`/admin/workstatus`, formData);
      getData();
      setRowSelectionIdx({});
    };

    getCheckModal({
      title: "هل انت متاكد من انك تريد تفعيل الأعمال المحددين",
      type: "warning",
      confirmButtonText: "نعم تفعيل",
      isConfirmedMsg: "تم التفعيل بنجاح",
      cb: deleteCallBack,
    });
  };
  const disActiveSelection = () => {
    const rowsIds = getRowsIds();

    if (!rowsIds?.length) {
      alert("لم تحدد اي عنصر");
    }

    const deleteCallBack = async () => {
      const formData = new FormData();
      const token = cookie.load("token");

      formData.append("token", token);
      formData.append("id", rowsIds);
      formData.append("status", 1);
      formData.append("type", "request");
      formData.append("event", "new");

      await API.post(`/admin/workstatus`, formData);
      getData();
      setRowSelectionIdx({});
    };

    getCheckModal({
      title: "هل انت متاكد من انك تريد تعطيل الأعمال المحددين",
      type: "warning",
      confirmButtonText: "نعم تعطيل",
      isConfirmedMsg: "تم التعطيل بنجاح",
      cb: deleteCallBack,
    });
  };

  useEffect(() => {
    const rowsIds = getRowsIds();

    if (rowsIds?.length) {
      setIsBtnActive(true);
    } else {
      setIsBtnActive(false);
    }
  }, [getRowsIds, rowSelectionIdx]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <article className="mb-5">
      <h2 className="mb-3">طلبات اضافة أعمال مستحبة</h2>

      <div style={{ maxHeight: 800, maxWidth: 1200, overflow: "auto" }}>
        <MaterialReactTable
          enableRowOrdering
          enablePagination={false}
          enableSorting={false}
          pagination={false}
          onRowSelectionChange={setRowSelectionIdx}
          columns={columns}
          data={data}
          state={{ rowSelection: rowSelectionIdx }}
          initialState={{ showColumnFilters: true }}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          enableColumnActions={false}
          enableBottomToolbar={false}
          enableColumnOrdering
          enableGrouping
          enablePinning
          enableRowSelection
          positionToolbarAlertBanner="bottom"
          tableInstanceRef={tableInstanceRef}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                data.splice(
                  hoveredRow.index,
                  0,
                  data.splice(draggingRow.index, 1)[0]
                );
                setData([...data]);
              }
            },
          })}
          renderDetailPanel={({ row }) => (
            <Box className="text-end">
              <div className="data d-flex gap-2">
                <p className="fs-5">التفاصيل:</p>

                <span style={{ whiteSpace: "pre-wrap" }} className="fs-5">
                  {row.original.description}
                </span>
              </div>
              <div className="data d-flex gap-2">
                <p className="fs-5">الثواب:</p>

                <span style={{ whiteSpace: "pre-wrap" }} className="fs-5">
                  {row.original.reward}
                </span>
              </div>
              <div className="data d-flex gap-2">
                <p className="fs-5">النوع:</p>

                <span style={{ whiteSpace: "pre-wrap" }} className="fs-5">
                  {row.original.type}
                </span>
              </div>
              <div className="data d-flex gap-2">
                <p className="fs-5">شهر:</p>

                <span style={{ whiteSpace: "pre-wrap" }} className="fs-5">
                  {row.original.month}
                </span>
              </div>
              <div className="data d-flex gap-2">
                <p className="fs-5">له عدد:</p>

                <span style={{ whiteSpace: "pre-wrap" }} className="fs-5">
                  {row.original.isNum}
                </span>
              </div>
              <div className="data d-flex gap-2">
                <p className="fs-5">الحد الاعلى للعد:</p>

                <span style={{ whiteSpace: "pre-wrap" }} className="fs-5">
                  {row.original.maximum}
                </span>
              </div>{" "}
              <div className="data d-flex gap-2">
                <p className="fs-5">المصدر:</p>

                <span style={{ whiteSpace: "pre-wrap" }} className="fs-5">
                  {row.original.resource}
                </span>
              </div>{" "}
              <div className="data d-flex gap-2">
                <p className="fs-5">شيخ:</p>

                <span style={{ whiteSpace: "pre-wrap" }} className="fs-5">
                  {row.original.sheikh}
                </span>
              </div>
            </Box>
          )}
          renderTopToolbarCustomActions={({ table }) => {
            return (
              <ButtonGroup className="ms-4">
                <Button
                  onClick={saveOrder}
                  variant="primary"
                  className="flex-center text-white ms-4"
                >
                  <AiOutlinePlus size={20} />
                  <span className="ms-1">حفظ الترتيب</span>
                </Button>

                <ButtonGroup className="ms-4">
                  <Button
                    onClick={activeSelection}
                    disabled={!isBtnActive}
                    variant="primary"
                    className="flex-center text-white"
                  >
                    <i className="bi bi-check2 fs-5 flex-center" />
                    <span className="ms-1">فعال</span>
                  </Button>

                  <Button
                    onClick={disActiveSelection}
                    disabled={!isBtnActive}
                    variant="warning"
                    className="flex-center text-white"
                  >
                    <MdNotInterested size={20} />
                    <span className="ms-1">غير فعال</span>
                  </Button>

                  <Button
                    onClick={deleteRows}
                    disabled={!isBtnActive}
                    variant="danger"
                    className="flex-center text-white"
                  >
                    <i className="bi bi-trash3" />
                    <span className="ms-1">حذف</span>
                  </Button>
                </ButtonGroup>
              </ButtonGroup>
            );
          }}
        />
      </div>
    </article>
  );
};

export default MessagesRequests;
