import React from "react";

// layout
import FullLayout from "../../layouts/FullLayout";

import PracticalMessagesItems from "./PracticalMessagesItems";
import PracticalMessagesSections from "./PracticalMessagesSections";

const Practical_messages = () => {
  return (
    <FullLayout title="الرسائل العملية">
      <PracticalMessagesSections />
      <PracticalMessagesItems />
    </FullLayout>
  );
};

export default Practical_messages;
