import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// layout
import FullLayout from "../../layouts/FullLayout";

//MRT Imports
import MaterialReactTable from "material-react-table";

//Material-UI Imports
import { Box, IconButton } from "@mui/material";

import { getCheckModal } from "../../utils/getModal";

// icons
import { AiOutlinePlus } from "react-icons/ai";

// components
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  FormGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { Label } from "reactstrap";

import default_img from "../../assets/images/users/user5.jpg";
import { useForm } from "react-hook-form";

// cookies
import cookie from "react-cookies";

// apis
import API from "../../utils/API";
import axios from "axios";

import moment from "moment";
import getMessage from "../../utils/getMessage";

const Banners = () => {
  const { register, handleSubmit, watch, reset, setValue } = useForm();
  const tableInstanceRef = useRef();

  const [arPreview, setArPreview] = useState("");
  const [show, setShow] = useState({ isShow: false, id: 0 });

  const [data, setData] = useState([]);
  const [isBtnActive, setIsBtnActive] = useState(false);
  const [rowSelectionIdx, setRowSelectionIdx] = useState({});
  const [currentBanner, setCurrentBanner] = useState({});

  const openModal = (id) => {
    setShow({ isShow: true, id });
    setCurrentBanner({});
    if (id === 0) {
      reset({
        ar_img: [],
        ar_link: "",
        duration: "",
        show_method: "",
      });
    }
  };

  const closeModal = () => {
    setShow({ isShow: false, id: 0 });

    reset({
      ar_img: [],
      ar_link: "",
      duration: "",
      show_method: "",
    });
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "img",
        id: "img",
        header: "صورة",
        size: 100,
        Cell: ({ row }) => (
          <Box className="text-end d-flex align-items-center">
            <img
              style={{ width: 50, height: 50, borderRadius: "100%" }}
              src={row.original?.img}
              alt=""
            />
          </Box>
        ),
      },
      {
        accessorKey: "duration",
        id: "duration",
        header: "المدة",
        size: 50,
        Cell: ({ row }) => (
          <Box className="text-center">
            <span languge>{row.original?.duration}</span>
          </Box>
        ),
      },
      {
        accessorKey: "to",
        id: "to",
        header: "الي",
        size: 50,
        Cell: ({ row }) => (
          <Box className="text-center">
            <span>{row.original?.to}</span>
          </Box>
        ),
      },
      {
        id: "createdAt",
        header: "انشأ بتاريخ",
        Cell: ({ row }) => (
          <Box className="text-end">
            <span>{moment(row.original?.createdAt).fromNow()}</span>
          </Box>
        ),
      },
      {
        id: "actions",
        header: "العمليات",
        Cell: ({ row }) => (
          <Box className="text-end">
            <IconButton onClick={() => openModal(row.original.id)}>
              <i className="bi bi-pencil-square fs-5"></i>
            </IconButton>
          </Box>
        ),
      },
    ],
    []
  );

  const getData = () => {
    const token = cookie.load("token");

    API(`/admin/banners?token=${token}`).then(({ data }) => {
      const collectedData = data?.Data?.map((banner) => ({
        id: banner?.id,
        img: banner?.image,
        duration: banner?.duration,
        url_ar: banner?.url_ar,
        perview: banner?.perview?.toString(),
        to: banner?.to == 1 ? "مستخدمين" : "شيوخ",
        createdAt: banner?.created_at,
        seq: banner?.seq,
      }));

      // Sort the collectedData array based on the seq field
      collectedData.sort((a, b) => a.seq - b.seq);

      setData(collectedData);
    });
  };

  const getRowsIds = useCallback(() => {
    const rowSelectionIds = Object.keys(rowSelectionIdx || {}).map((idx) => {
      return data[idx]?.id;
    });

    return rowSelectionIds;
  }, [data, rowSelectionIdx]);
  const saveOrder = async () => {
    const token = cookie.load("token");
    const dataWithSeq = data?.map((item, idx) => ({ ...item, seq: idx + 1 }));
    console.log(dataWithSeq);
    try {
      await API.post(`/admin/reorderData?token=${token}`, {
        table_name: "banners",
        data: dataWithSeq,
      });
      getMessage("success", "تم حفظ الترتيب");

      getData();
    } catch (error) {
      console.error("Error saving order:", error);
      getMessage("error", "حدث خطأ أثناء حفظ الترتيب");
    } finally {
      closeModal();
    }
  };
  const deleteRows = () => {
    const rowsIds = getRowsIds();

    if (!rowsIds?.length) {
      alert("لم تحدد اي عنصر");
    }

    const deleteCallBack = async () => {
      const token = cookie.load("token");

      await API(`/admin/deleteBanners?token=${token}&id=${rowsIds}`);
      getData();
      setRowSelectionIdx({});
    };

    getCheckModal({
      title: "هل انت متاكد من انك تريد حذف الغلافات المحدده",
      type: "warning",
      confirmButtonText: "نعم حذف",
      isConfirmedMsg: "تم الحذف بنجاح",
      cb: deleteCallBack,
    });
  };
  const saveHandelar = async (data) => {
    const token = cookie.load("token");
    const formData = new FormData();

    show?.id && formData.append("id", show?.id);
    data?.ar_img?.[0] && formData.append("image_ar", data?.ar_img?.[0]);
    data?.ar_img?.[0] && formData.append("image_en", data?.ar_img?.[0]);
    data?.ar_link && formData.append("url_ar", data?.ar_link);
    data?.ar_link && formData.append("url_en", data?.ar_link);
    formData.append("token", token);
    formData.append("to", +data?.to);
    formData.append("duration", data?.duration);
    formData.append("perview", 1);

    await API.post(
      show?.id ? `admin/updateBanners` : `admin/createBanners`,
      formData
    )
      .then(({ data }) => {
        if (data?.code != 200) {
          getMessage("error", "يرجي ملي جميع الحقول");
        }
      })
      .catch(() => {
        getMessage("error", "يرجي ملي جميع الحقول");
      });

    getData();
    closeModal();
  };

  const openFile = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    const getImageData = async () => {
      if (watch("ar_img")?.length) {
        const imageData = await openFile(watch("ar_img")[0]);
        setArPreview(imageData);
      } else if (currentBanner.img) {
        // If no new image is selected, but there's an existing image for the current banner
        setArPreview(currentBanner.img);
      } else {
        // If no image is selected and no existing image for the current banner
        setArPreview("");
      }
    };

    getImageData();
  }, [watch("ar_img"), currentBanner.img]);

  useEffect(() => {
    const rowsIds = getRowsIds();

    if (rowsIds?.length) {
      setIsBtnActive(true);
    } else {
      setIsBtnActive(false);
    }
  }, [getRowsIds, rowSelectionIdx]);

  useEffect(() => {
    const getData = async () => {
      if (watch("ar_img")?.length) {
        const data = await openFile(watch("ar_img")[0]);

        setArPreview((prev) => {
          return prev === data ? prev : data;
        });
      } else {
        setArPreview("");
      }
    };

    getData();
  });

  useEffect(() => {
    getData();
  }, []);

  // get banner data
  useEffect(() => {
    const getData = async () => {
      if (show?.id) {
        const currentBanner = data?.find((banner) => banner.id === show.id);
        console.log("test", currentBanner);
        setValue("duration", currentBanner?.duration);
        setValue("ar_link", currentBanner?.url_ar);
        setValue("to", currentBanner?.to === "مستخدمين" ? 1 : 2);
        setValue("show_method", currentBanner?.perview);

        // Check if the image exists in currentBanner
        if (currentBanner?.img) {
          setValue("ar_img", currentBanner.img);
          setCurrentBanner(currentBanner); // Move this line here
        } else {
          axios
            .get(currentBanner?.img, { responseType: "blob" })
            .then((res) => {
              const blob = res.data;
              const file = new File([blob], "image.jpg", {
                type: "image/jpeg",
              });
              setValue("ar_img", [file]);
            });
          console.log(currentBanner);
        }
      }
    };

    getData();
  }, [data, show.id, setValue]);

  // Move the logic here to set the src attribute of the <img> tag

  return (
    <FullLayout
      title="غلافات الإعلانات"
      renderButtons={() => (
        <div className="flex-center">
          <ButtonGroup className="ms-4">
            <Button
              onClick={deleteRows}
              disabled={!isBtnActive}
              variant="danger"
              className="flex-center text-white"
            >
              <i className="bi bi-trash3" />
              <span className="ms-1">حذف</span>
            </Button>
          </ButtonGroup>
          <Button
            onClick={saveOrder}
            variant="primary"
            className="flex-center text-white ms-4"
          >
            <AiOutlinePlus size={20} />
            <span className="ms-1">حفظ الترتيب</span>
          </Button>
          <Button
            onClick={() => openModal(0)}
            variant="primary"
            className="flex-center text-white"
          >
            <AiOutlinePlus size={20} />
            <span className="ms-1">اضافه</span>
          </Button>
        </div>
      )}
    >
      <div style={{ maxHeight: 800, maxWidth: 1200, overflow: "auto" }}>
        <MaterialReactTable
          enableRowOrdering
          enableSorting={false}
          enablePagination={false}
          pagination={false}
          onRowSelectionChange={setRowSelectionIdx}
          columns={columns}
          data={data}
          state={{ rowSelection: rowSelectionIdx }}
          initialState={{ showColumnFilters: true }}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          enableColumnActions={false}
          enableBottomToolbar={false}
          enableColumnOrdering
          enableGrouping
          enablePinning
          enableRowSelection
          positionToolbarAlertBanner="bottom"
          tableInstanceRef={tableInstanceRef}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                data.splice(
                  hoveredRow.index,
                  0,
                  data.splice(draggingRow.index, 1)[0]
                );
                setData([...data]);
              }
            },
          })}
        />
      </div>
      {/* Edit Modal or Add */}
      <Modal centered show={show.isShow} onHide={closeModal}>
        <Modal.Header>
          <Modal.Title>اضافة او تعديل غلافات الإعلانات</Modal.Title>

          <button className="btn-close me-auto ms-0" onClick={closeModal} />
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3 border-bottom">
            <Row className="mb-3">
              <Label>الصورة</Label>
              <div className="imgPlaceholder flex-center">
                <label
                  htmlFor="ar_img"
                  style={{
                    width: 80,
                    height: 80,
                    cursor: "pointer",
                    borderRadius: "50%",
                    overflow: "hidden",
                  }}
                >
                  {/* Ensure currentBanner is defined before accessing it */}
                  <img
                    className="w-100 h-100"
                    src={
                      arPreview ? arPreview : currentBanner?.img || default_img
                    }
                    alt=""
                  />
                </label>
              </div>
              <input
                type="file"
                name="ar_img"
                id="ar_img"
                hidden
                {...register("ar_img")}
              />
            </Row>
          </Form.Group>

          <Form.Group className="mb-3">
            <Row className="mb-3">
              <Col lg="6">
                <FormGroup>
                  <Label for="ar_link">الرابط</Label>
                  <Form.Control
                    id="ar_link"
                    type="text"
                    {...register("ar_link")}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <Label for="duration">المدة</Label>
                  <Form.Control
                    id="duration"
                    type="text"
                    {...register("duration")}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form.Group>

          <Form.Group className="mb-3">
            <Row className="mb-3">
              <FormGroup>
                <Label for="to">عرض للـ</Label>
                <Form.Control id="to" as="select" {...register("to")}>
                  <option value="1">مستخدمين</option>
                  <option value="2">شيوخ</option>
                </Form.Control>
              </FormGroup>
            </Row>
          </Form.Group>

          {/* <Form.Group className="mb-3">
            <Row className="mb-3">
              <FormGroup>
                <Label for="show_method">عرض بالـ</Label>
                <Form.Control
                  id="show_method"
                  as="select"
                  {...register("show_method")}
                >
                  <option value="0">مراض العرض</option>
                  <option value="1">الايام</option>
                </Form.Control>
              </FormGroup>
            </Row>
          </Form.Group> */}
        </Modal.Body>
        <Modal.Footer className="flex-center">
          <Button variant="primary" onClick={handleSubmit(saveHandelar)}>
            حفظ
          </Button>

          <Button variant="danger" onClick={closeModal}>
            الغاء
          </Button>
        </Modal.Footer>
      </Modal>
    </FullLayout>
  );
};

export default Banners;
