import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

//MRT Imports
import MaterialReactTable from "material-react-table";

//Material-UI Imports
import { Box, IconButton } from "@mui/material";

import { getCheckModal } from "../../../utils/getModal";

// icons
import { MdNotInterested } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";

// components
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  FormGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { Label } from "reactstrap";

// import { data as collectedData } from "./makeData";
import { useForm } from "react-hook-form";

// cookies
import cookie from "react-cookies";

// apis
import API from "../../../utils/API";

import moment from "moment";
import getMessage from "../../../utils/getMessage";

const worksSectionsId = 4;

const months = [
  { id: 1, name: "محرم" },
  { id: 2, name: "صفر" },
  { id: 3, name: "ربيع أول" },
  { id: 4, name: "ربيع ثاني" },
  { id: 5, name: "جماد أول" },
  { id: 6, name: "جماد ثاني" },
  { id: 7, name: "رجب" },
  { id: 8, name: "شعبان" },
  { id: 9, name: "رمضان" },
  { id: 10, name: "شوال" },
  { id: 11, name: "ذو القعدة" },
  { id: 12, name: "ذو الحجة" },
];

const WorksItems = ({ sections, setSections }) => {
  const { handleSubmit, register, reset, setValue } = useForm();

  const [show, setShow] = useState({ isShow: false, id: 0 });
  const [data, setData] = useState([]);
  const [isBtnActive, setIsBtnActive] = useState(false);
  const [rowSelectionIdx, setRowSelectionIdx] = useState({});
  const tableInstanceRef = useRef();

  const openModal = (id) => {
    setShow({ isShow: true, id });
  };
  const closeModal = () => {
    setShow({ isShow: false, id: 0 });

    reset({
      isNum: "1",
      maximum: "",
      ar_details: "",
      ar_reward: "",
    });
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "description",
        id: "description",
        header: "التفاصيل",
        size: 250,
        Cell: ({ row }) => (
          <Box className="text-end d-flex align-items-center">
            <span>{row.original?.description}</span>
          </Box>
        ),
      },
      {
        accessorKey: "isNum",
        id: "isNum",
        header: "له عدد",
        size: 100,
        Cell: ({ row }) => (
          <Box className="text-end d-flex align-items-center">
            <span>{row.original?.isNum}</span>
          </Box>
        ),
      },
      {
        accessorKey: "section",
        id: "section",
        header: "القسم",
        size: 100,
        Cell: ({ row }) => (
          <Box className="text-end d-flex align-items-center">
            <span>
              {sections?.find((sec) => sec?.id == row.original?.section_id)
                ?.name || "لا يوجد قسم"}
            </span>
          </Box>
        ),
      },
      {
        accessorKey: "maximum",
        id: "maximum",
        header: "	الحد الاعلى للعد",
        size: 100,
        Cell: ({ row }) => (
          <Box className="text-end d-flex align-items-center">
            <span>{row.original?.maximum}</span>
          </Box>
        ),
      },
      {
        accessorKey: "status",
        id: "status",
        header: "الحالة",
        size: 50,
        Cell: ({ row }) => (
          <Box
            className={`w-fit text-center px-2 py-1 rounded-3 m-auto ${
              row.original?.status === "فعال" ? "bg-movee" : "bg-danger"
            }`}
          >
            <span className="text-white">{row.original?.status}</span>
          </Box>
        ),
      },
      {
        id: "createdAt",
        header: "انشأ بتاريخ",
        Cell: ({ row }) => (
          <Box className="text-end">
            <span>{moment(row.original?.createdAt).fromNow()}</span>
          </Box>
        ),
      },
      {
        id: "actions",
        header: "العمليات",
        Cell: ({ row }) => (
          <Box className="text-end">
            <IconButton onClick={() => openModal(row.original.id)}>
              <i className="bi bi-pencil-square fs-5"></i>
            </IconButton>
          </Box>
        ),
      },
    ],
    [sections]
  );
  const getData = () => {
    const token = cookie.load("token");

    API(`/admin/works?token=${token}`).then(({ data }) => {
      const collectedData = data?.Data?.map((data) => ({
        id: data?.id,
        description: data?.details,
        isNum: data?.has_counter ? "نعم" : "لا",
        maximum: data?.max_count,
        section_id: data?.section_id,
        reward: data?.reward,
        status: data?.status === "active" ? "فعال" : "غير فعال",
        createdAt: data?.created_at,
        seq: data?.seq,
      }));

      // Sort the collectedData array based on the seq field
      collectedData.sort((a, b) => a.seq - b.seq);

      setData(collectedData);
    });

    // API(`/admin/sections?token=${token}&sub_to=${worksSectionsId}`).then(
    //   ({ data }) => {
    //     const collectedData = data?.Data?.map((section) => ({
    //       id: section?.id,
    //       name: section?.name,
    //     }));

    //     setSections(collectedData);
    //   }
    // );
  };
  const getRowsIds = useCallback(() => {
    const rowSelectionIds = Object.keys(rowSelectionIdx || {}).map((idx) => {
      return data[idx]?.id;
    });

    return rowSelectionIds;
  }, [data, rowSelectionIdx]);
  const deleteRows = () => {
    const rowsIds = getRowsIds();

    if (!rowsIds?.length) {
      alert("لم تحدد اي عنصر");
    }

    const deleteCallBack = async () => {
      const formData = new FormData();
      const token = cookie.load("token");

      formData.append("token", token);
      formData.append("id", rowsIds);
      formData.append("event", "delete");
      formData.append("type", "work");

      await API.post(`/admin/workstatus`, formData);
      getData();
      setRowSelectionIdx({});
    };

    getCheckModal({
      title: "هل انت متاكد من انك تريد حذف الأعمال المحدده",
      type: "warning",
      confirmButtonText: "نعم حذف",
      isConfirmedMsg: "تم الحذف بنجاح",
      cb: deleteCallBack,
    });
  };
  const activeSelection = () => {
    const rowsIds = getRowsIds();

    if (!rowsIds?.length) {
      alert("لم تحدد اي عنصر");
    }

    const deleteCallBack = async () => {
      const formData = new FormData();
      const token = cookie.load("token");

      formData.append("token", token);
      formData.append("id", rowsIds);
      formData.append("event", "active");
      formData.append("type", "work");

      await API.post(`/admin/workstatus`, formData);
      getData();
      setRowSelectionIdx({});
    };

    getCheckModal({
      title: "هل انت متاكد من انك تريد تفعيل الأعمال المحددين",
      type: "warning",
      confirmButtonText: "نعم تفعيل",
      isConfirmedMsg: "تم التفعيل بنجاح",
      cb: deleteCallBack,
    });
  };
  const disActiveSelection = () => {
    const rowsIds = getRowsIds();

    if (!rowsIds?.length) {
      alert("لم تحدد اي عنصر");
    }

    const deleteCallBack = async () => {
      const formData = new FormData();
      const token = cookie.load("token");

      formData.append("token", token);
      formData.append("id", rowsIds);
      formData.append("event", "not_active");
      formData.append("type", "work");

      await API.post(`/admin/workstatus`, formData);
      getData();
      setRowSelectionIdx({});
    };

    getCheckModal({
      title: "هل انت متاكد من انك تريد تعطيل الأعمال المحددين",
      type: "warning",
      confirmButtonText: "نعم تعطيل",
      isConfirmedMsg: "تم التعطيل بنجاح",
      cb: deleteCallBack,
    });
  };
  const saveHandelar = async (data) => {
    const token = cookie.load("token");
    const formData = new FormData();

    show?.id && formData.append("id", show?.id);
    formData.append("token", token);
    formData.append("has_counter", data?.isNum);
    formData.append("max_count", data?.maximum || "1");
    formData.append("details_ar", data?.ar_details);
    formData.append("reward_ar", data?.ar_reward);
    formData.append("section_id", data?.section_id);

    await API.post(
      show?.id ? "/admin/worksUpdate" : "/admin/worksStore",
      formData
    )
      .then(({ data }) => {
        if (data?.code != 200) {
          getMessage("error", "يرجي ملي جميع الحقول");
        }
      })
      .catch(() => {
        getMessage("error", "يرجي ملي جميع الحقول");
      });
    getData();
    closeModal();
  };
  const saveOrder = async () => {
    const token = cookie.load("token");
    const dataWithSeq = data?.map((item, idx) => ({ ...item, seq: idx + 1 }));
    console.log(dataWithSeq);
    try {
      await API.post(`/admin/reorderNestedData?token=${token}`, {
        main_table_name: "works",
        secondary_table_name: "work_translations",
        key: "work_id",
        data: dataWithSeq,
      });
      getMessage("success", "تم حفظ الترتيب");

      getData();
    } catch (error) {
      console.error("Error saving order:", error);
      getMessage("error", "حدث خطأ أثناء حفظ الترتيب");
    } finally {
      closeModal();
    }
  };

  useEffect(() => {
    const rowsIds = getRowsIds();

    if (rowsIds?.length) {
      setIsBtnActive(true);
    } else {
      setIsBtnActive(false);
    }
  }, [getRowsIds, rowSelectionIdx]);

  useEffect(() => {
    getData();
  }, []);

  // get data
  useEffect(() => {
    if (show?.id) {
      const currentData = data?.find((data) => data.id === show.id);

      setValue("isNum", currentData?.isNum ? "1" : "2");
      setValue("maximum", currentData?.maximum);
      setValue("section_id", currentData?.section_id);
      setValue("ar_details", currentData?.description);
      setValue("ar_reward", currentData?.reward);
    }
  }, [data, show.id, setValue]);

  return (
    <article className="mb-5">
      <h2 className="mb-3">الأعمال المستحبة</h2>

      <div style={{ maxHeight: 800, maxWidth: 1200, overflow: "auto" }}>
        <MaterialReactTable
          enablePagination={false}
          onRowSelectionChange={setRowSelectionIdx}
          columns={columns}
          data={data}
          state={{ rowSelection: rowSelectionIdx }}
          initialState={{ showColumnFilters: true }}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          enableColumnActions={false}
          enableBottomToolbar={false}
          enableColumnOrdering
          enableGrouping
          enablePinning
          enableRowSelection
          positionToolbarAlertBanner="bottom"
          tableInstanceRef={tableInstanceRef}
          enableRowOrdering
          enableSorting={false}
          renderTopToolbarCustomActions={() => (
            <div className="flex-center">
              <ButtonGroup className="ms-4">
                <Button
                  onClick={activeSelection}
                  disabled={!isBtnActive}
                  variant="primary"
                  className="flex-center text-white"
                >
                  <i className="bi bi-check2 fs-5 flex-center" />
                  <span className="ms-1">فعال</span>
                </Button>

                <Button
                  onClick={disActiveSelection}
                  disabled={!isBtnActive}
                  variant="warning"
                  className="flex-center text-white"
                >
                  <MdNotInterested size={20} />
                  <span className="ms-1">غير فعال</span>
                </Button>

                <Button
                  onClick={deleteRows}
                  disabled={!isBtnActive}
                  variant="danger"
                  className="flex-center text-white"
                >
                  <i className="bi bi-trash3" />
                  <span className="ms-1">حذف</span>
                </Button>
              </ButtonGroup>

              <Button
                onClick={() => openModal(0)}
                variant="primary"
                className="flex-center text-white"
              >
                <AiOutlinePlus size={20} />
                <span className="ms-1">اضافه</span>
              </Button>

              <Button
                onClick={saveOrder}
                variant="primary"
                className="flex-center text-white ms-4"
              >
                <AiOutlinePlus size={20} />
                <span className="ms-1">حفظ الترتيب</span>
              </Button>
            </div>
          )}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                data.splice(
                  hoveredRow.index,
                  0,
                  data.splice(draggingRow.index, 1)[0]
                );
                setData([...data]);
              }
            },
          })}
        />
      </div>

      {/* Edit Modal or Add */}
      <Modal
        fullscreen
        centered
        show={show.isShow}
        onHide={closeModal}
        className="p-0"
      >
        <Modal.Header>
          <Modal.Title>اضافة او تعديل الأعمال</Modal.Title>

          <button className="btn-close me-auto ms-0" onClick={closeModal} />
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Row className="mb-3">
              <Col lg="6">
                <FormGroup>
                  <Label for="isNum">له عدد</Label>
                  <Form.Control id="isNum" as="select" {...register("isNum")}>
                    <option value="1">نعم</option>
                    <option value="2">لا</option>
                  </Form.Control>
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <Label for="maximum">الحد الاعلى للعد</Label>
                  <Form.Control
                    id="maximum"
                    type="text"
                    {...register("maximum")}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <FormGroup>
              <Label for="section_id">القسم</Label>
              <Form.Control
                as="select"
                id="section_id"
                {...register("section_id")}
              >
                {sections?.map((section, idx) => (
                  <option key={idx} value={section?.id}>
                    {section?.name}
                  </option>
                ))}
              </Form.Control>
            </FormGroup>
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Row className="mb-3">
              <FormGroup>
                <Label for="ar_details">االتفاصيل</Label>
                <Form.Control
                  id="ar_details"
                  type="text"
                  as="textarea"
                  {...register("ar_details")}
                  rows={8}
                />
              </FormGroup>
            </Row>
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Row className="mb-3">
              <FormGroup>
                <Label for="ar_reward">الثواب</Label>
                <Form.Control
                  id="ar_reward"
                  type="text"
                  as="textarea"
                  rows={8}
                  {...register("ar_reward")}
                />
              </FormGroup>
            </Row>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="flex-center">
          <Button variant="primary" onClick={handleSubmit(saveHandelar)}>
            حفظ
          </Button>

          <Button variant="danger" onClick={closeModal}>
            الغاء
          </Button>
        </Modal.Footer>
      </Modal>
    </article>
  );
};

export default WorksItems;
