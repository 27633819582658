import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

//MRT Imports
import MaterialReactTable from "material-react-table";

//Material-UI Imports
import { Box, IconButton } from "@mui/material";

import { getCheckModal } from "../../../utils/getModal";

// icons
import { MdNotInterested } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";

// components
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  FormGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { Label } from "reactstrap";

// import { data as collectedData } from "./makeData";
import { useForm } from "react-hook-form";

// cookies
import cookie from "react-cookies";

// apis
import API from "../../../utils/API";

import moment from "moment";
import getMessage from "../../../utils/getMessage";

const worksSectionsId = 3;

const sections = [
  {
    id: 1,
    name: "القسم 1",
    sources: [
      { name: "المرجع 1", id: 1 },
      { name: "المرجع 2", id: 2 },
    ],
  },
  {
    id: 2,
    name: "القسم 2",
    sources: [
      { name: "المرجع 3", id: 3 },
      { name: "المرجع 4", id: 4 },
    ],
  },
];

const FaqsItems = () => {
  const { handleSubmit, register, watch } = useForm();

  const [currentSources, setCurrentSources] = useState([]);
  const [show, setShow] = useState({ isShow: false, id: 0 });
  const [data, setData] = useState([]);
  const [isBtnActive, setIsBtnActive] = useState(false);
  const [rowSelectionIdx, setRowSelectionIdx] = useState({});
  const tableInstanceRef = useRef();

  const [sections, setSections] = useState([]);
  const [resorces, setResorces] = useState([]);

  const refrence = useRef([]);
  const sectionsRef = useRef([]);

  const openModal = (id) => {
    setShow({ isShow: true, id });
  };
  const closeModal = () => {
    setShow({ isShow: false, id: 0 });
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "#",
        id: "#",
        header: "#",
        size: 20,
        Cell: ({ row }) => (
          <Box className="text-center">
            <span>{row.original?.id}</span>
          </Box>
        ),
      },
      {
        accessorKey: "question",
        id: "question",
        header: "السؤال",
        size: 250,
        Cell: ({ row }) => (
          <Box className="text-end">
            <span>{row.original?.question}</span>
          </Box>
        ),
      },
      {
        accessorKey: "answer",
        id: "answer",
        header: "الإجابة",
        size: 250,
        Cell: ({ row }) => (
          <Box className="text-end">
            <span>{row.original?.answer}</span>
          </Box>
        ),
      },
      {
        accessorKey: "section",
        id: "section",
        header: "القسم",
        size: 150,
        Cell: ({ row }) => (
          <Box className="text-end">
            <span>{row.original?.section}</span>
          </Box>
        ),
      },
      {
        accessorKey: "resorce",
        id: "resorce",
        header: "المرجع",
        size: 100,
        Cell: ({ row }) => (
          <Box className="text-end">{row.original?.resorce}</Box>
        ),
      },
      {
        accessorKey: "source",
        id: "source",
        header: "مصدر العمل",
        size: 100,
        Cell: ({ row }) => (
          <Box className="text-end">
            <span>{row.original?.source}</span>
          </Box>
        ),
      },
      {
        accessorKey: "status",
        id: "status",
        header: "الحالة",
        size: 50,
        Cell: ({ row }) => (
          <Box
            className={`w-fit text-center px-2 py-1 rounded-3 m-auto ${
              row.original?.status === "فعال" ? "bg-movee" : "bg-danger"
            }`}
          >
            <span className="text-white">{row.original?.status}</span>
          </Box>
        ),
      },
      {
        id: "createdAt",
        header: "انشأ بتاريخ",
        Cell: ({ row }) => (
          <Box className="text-end">
            <span>{moment(row.original?.createdAt).fromNow()}</span>
          </Box>
        ),
      },
      {
        id: "actions",
        header: "العمليات",
        Cell: ({ row }) => (
          <Box className="text-end">
            <IconButton onClick={() => openModal(row.original.id)}>
              <i className="bi bi-pencil-square fs-5"></i>
            </IconButton>
          </Box>
        ),
      },
    ],
    []
  );
  const getData = useCallback(async () => {
    const token = cookie.load("token");

    await API(`/admin/references?token=${token}`).then(({ data }) => {
      const collectedData = data?.Data?.map((data) => ({
        id: data?.id,
        name: data?.name,
        section_id: data?.section_id,
      }));

      refrence.current = collectedData;
      setResorces(collectedData);
    });

    await API(`/admin/sections?token=${token}&sub_to=1`).then(({ data }) => {
      const collectedData = data?.Data?.map((section) => ({
        id: section?.id,
        name: section?.name,
        sources: refrence.current,
      }));

      sectionsRef.current = collectedData;
      setSections(collectedData);
    });

    await API(`/admin/faqs?token=${token}`).then(({ data }) => {
      const collectedData = data?.Data?.items?.map((data) => ({
        id: data?.id,
        question: data?.question,
        source: data?.source,
        answer: data?.answer,
        section: sectionsRef.current?.find((sec) => sec?.id == data?.section_id)
          ?.name,
        resorce: refrence.current?.find((sec) => sec?.id == data?.reference_id)
          ?.name,
        status: data?.status === "active" ? "فعال" : "غير فعال",
        createdAt: data?.created_at,
        seq: data?.seq,
      }));

      // Sort the collectedData array based on the seq field
      collectedData.sort((a, b) => a.seq - b.seq);

      setData(collectedData);
    });

    console.log("run");
  }, []);

  const getRowsIds = useCallback(() => {
    const rowSelectionIds = Object.keys(rowSelectionIdx || {}).map((idx) => {
      return data[idx]?.id;
    });

    return rowSelectionIds;
  }, [data, rowSelectionIdx]);
  const saveOrder = async () => {
    const token = cookie.load("token");
    const dataWithSeq = data?.map((item, idx) => ({ ...item, seq: idx + 1 }));
    console.log(dataWithSeq);
    try {
      await API.post(`/admin/reorderNestedData?token=${token}`, {
        main_table_name: "faqs",
        secondary_table_name: "faq_translations",
        key: "faq_id",
        data: dataWithSeq,
      });
      getMessage("success", "تم حفظ الترتيب");

      getData();
    } catch (error) {
      console.error("Error saving order:", error);
      getMessage("error", "حدث خطأ أثناء حفظ الترتيب");
    } finally {
      closeModal();
    }
  };
  const deleteRows = () => {
    const rowsIds = getRowsIds();

    if (!rowsIds?.length) {
      alert("لم تحدد اي عنصر");
    }

    const deleteCallBack = async () => {
      const formData = new FormData();
      const token = cookie.load("token");

      formData.append("token", token);
      formData.append("id", rowsIds);
      formData.append("event", "delete");
      formData.append("type", "faq");

      await API.post(`/admin/faqresources/changestatus`, formData);
      getData();
      setRowSelectionIdx({});
    };

    getCheckModal({
      title: "هل انت متاكد من انك تريد حذف الأسئلة المحدده",
      type: "warning",
      confirmButtonText: "نعم حذف",
      isConfirmedMsg: "تم الحذف بنجاح",
      cb: deleteCallBack,
    });
  };
  const activeSelection = () => {
    const rowsIds = getRowsIds();

    if (!rowsIds?.length) {
      alert("لم تحدد اي عنصر");
    }

    const deleteCallBack = async () => {
      const formData = new FormData();
      const token = cookie.load("token");

      formData.append("token", token);
      formData.append("id", rowsIds);
      formData.append("event", "active");
      formData.append("type", "faq");

      await API.post(`/admin/faqresources/changestatus`, formData);
      getData();
      setRowSelectionIdx({});
    };

    getCheckModal({
      title: "هل انت متاكد من انك تريد تفعيل الأسئلة المحدده",
      type: "warning",
      confirmButtonText: "نعم تفعيل",
      isConfirmedMsg: "تم التفعيل بنجاح",
      cb: deleteCallBack,
    });
  };
  const disActiveSelection = () => {
    const rowsIds = getRowsIds();

    if (!rowsIds?.length) {
      alert("لم تحدد اي عنصر");
    }

    const deleteCallBack = async () => {
      const formData = new FormData();
      const token = cookie.load("token");

      formData.append("token", token);
      formData.append("id", rowsIds);
      formData.append("event", "not_active");
      formData.append("type", "faq");

      await API.post(`/admin/faqresources/changestatus`, formData);
      getData();
      setRowSelectionIdx({});
    };

    getCheckModal({
      title: "هل انت متاكد من انك تريد تعطيل الأسئلة المحدده",
      type: "warning",
      confirmButtonText: "نعم تعطيل",
      isConfirmedMsg: "تم التعطيل بنجاح",
      cb: deleteCallBack,
    });
  };
  const saveHandelar = async (data) => {
    const token = cookie.load("token");

    const asnwarsData = Object.keys(data)?.filter((key) =>
      key?.includes("ar_answer_")
    );
    const answers = asnwarsData?.map((answer) => {
      const id = answer?.slice(11)?.replace("}", "");
      const source = data[`source_{${id}}`];
      const answers = data[`ar_answer_{${id}}`];

      return { answer: answers, source, id, seq: 1 };
    });
    const dataCollect = {
      ...(show?.id ? { id: show?.id } : {}),
      section: data?.section,
      answer_en: answers,
      answer_ar: answers,
      token,
      question_ar: [{ question: data?.ar_ques }],
      question_en: [{ question: data?.ar_ques }],
      seq: "1",
    };

    await API.post(
      show?.id ? "/admin/updateFaqs" : "/admin/CreateFaqs",
      dataCollect
    ).catch(() => {
      getMessage("error", "يرجي ملي جميع الحقول");
    });
    getData();
    closeModal();
  };
  // const saveOrder = async () => {
  //   const dataWithSeq = data?.map((data, idx) => ({ ...data, seq: idx + 1 }));

  //   await API.post("/admin/saveFaqsOrder", dataWithSeq).finally(() => {
  //     getMessage("success", "تم حفظ الترتيب");
  //   });

  //   getData();
  //   closeModal();
  // };

  useEffect(() => {
    const rowsIds = getRowsIds();

    if (rowsIds?.length) {
      setIsBtnActive(true);
    } else {
      setIsBtnActive(false);
    }
  }, [getRowsIds, rowSelectionIdx]);

  useEffect(() => {
    if (watch("section")) {
      const section = sections?.find(
        (section) => section?.id == watch("section")
      );

      setCurrentSources(section?.sources || []);
    }
  }, [watch("section"), sections]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <article className="mb-5">
      <h2 className="mb-3">الاسئله</h2>

      <div style={{ maxHeight: 800, maxWidth: 1200, overflow: "auto" }}>
        <MaterialReactTable
          enableRowOrdering
          enableSorting={false}
          enablePagination={false}
          pagination={false}
          onRowSelectionChange={setRowSelectionIdx}
          columns={columns}
          data={data}
          state={{ rowSelection: rowSelectionIdx }}
          initialState={{ showColumnFilters: true }}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          enableColumnActions={false}
          enableBottomToolbar={false}
          enableColumnOrdering
          enableGrouping
          enablePinning
          enableRowSelection
          positionToolbarAlertBanner="bottom"
          tableInstanceRef={tableInstanceRef}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                data.splice(
                  hoveredRow.index,
                  0,
                  data.splice(draggingRow.index, 1)[0]
                );
                setData([...data]);
              }
            },
          })}
          renderTopToolbarCustomActions={() => (
            <div className="flex-center">
              <ButtonGroup className="ms-4">
                <Button
                  onClick={activeSelection}
                  disabled={!isBtnActive}
                  variant="primary"
                  className="flex-center text-white"
                >
                  <i className="bi bi-check2 fs-5 flex-center" />
                  <span className="ms-1">فعال</span>
                </Button>

                <Button
                  onClick={disActiveSelection}
                  disabled={!isBtnActive}
                  variant="warning"
                  className="flex-center text-white"
                >
                  <MdNotInterested size={20} />
                  <span className="ms-1">غير فعال</span>
                </Button>

                <Button
                  onClick={deleteRows}
                  disabled={!isBtnActive}
                  variant="danger"
                  className="flex-center text-white"
                >
                  <i className="bi bi-trash3" />
                  <span className="ms-1">حذف</span>
                </Button>
              </ButtonGroup>

              <Button
                onClick={() => openModal(0)}
                variant="primary"
                className="flex-center text-white"
              >
                <AiOutlinePlus size={20} />
                <span className="ms-1">اضافه</span>
              </Button>

              <Button
                onClick={saveOrder}
                variant="primary"
                className="flex-center text-white ms-4"
              >
                <AiOutlinePlus size={20} />
                <span className="ms-1">حفظ الترتيب</span>
              </Button>
            </div>
          )}
        />
      </div>

      {/* Edit Modal or Add */}
      <Modal
        className="p-0"
        fullscreen
        centered
        show={show.isShow}
        onHide={closeModal}
      >
        <Modal.Header>
          <Modal.Title>اضافة او تعديل الأسئلة</Modal.Title>

          <button className="btn-close me-auto ms-0" onClick={closeModal} />
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Row className="mb-3">
              <Col lg="6">
                <FormGroup>
                  <Label for="ar_ques">السؤال</Label>
                  <Form.Control
                    id="ar_ques"
                    type="text"
                    {...register("ar_ques")}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <Label for="section">القسم</Label>
                  <Form.Control
                    id="section"
                    as="select"
                    {...register("section")}
                  >
                    <option selected value={`00`}>
                      اختر قسم
                    </option>
                    {sections?.map((resorce, idx) => (
                      <option key={idx} value={resorce?.id}>
                        {resorce?.name}
                      </option>
                    ))}
                  </Form.Control>
                </FormGroup>
              </Col>
            </Row>
          </Form.Group>

          <h2 style={{ width: "fit-content" }} className="mt-4 mb-3">
            المراجع المتاحه
          </h2>

          {currentSources?.map((source, idx) => (
            <article key={idx}>
              {idx != 0 && <hr />}
              <h4>{source?.name}</h4>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Row className="mb-3">
                  <Col lg="12" className="mt-3">
                    <FormGroup>
                      <Label for="ar_answer">الإجابة في {source?.name}</Label>
                      <Form.Control
                        id="ar_answer"
                        as="textarea"
                        rows={6}
                        {...register(`ar_answer_{${source?.id}}`)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Row className="mb-3">
                  <Col lg="12" className="mt-3">
                    <FormGroup>
                      <Label for="source">مصدر العمل في {source?.name}</Label>
                      <Form.Control
                        id="source"
                        as="textarea"
                        rows={6}
                        {...register(`source_{${source?.id}}`)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form.Group>
            </article>
          ))}
        </Modal.Body>
        <Modal.Footer className="flex-center">
          <Button variant="primary" onClick={handleSubmit(saveHandelar)}>
            حفظ
          </Button>

          <Button variant="danger" onClick={closeModal}>
            الغاء
          </Button>
        </Modal.Footer>
      </Modal>
    </article>
  );
};

export default FaqsItems;
