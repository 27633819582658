import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

//MRT Imports
import MaterialReactTable from "material-react-table";

//Material-UI Imports
import { Box } from "@mui/material";

//Mock Data
// import { data as collectedData } from "./makeData";
import FullLayout from "../../layouts/FullLayout";
import { getCheckModal } from "../../utils/getModal";
import { AiOutlinePlus } from "react-icons/ai";
import { useForm } from "react-hook-form";

// cookies
import cookie from "react-cookies";

// apis
import API from "../../utils/API";

import moment from "moment";
import { Button, ButtonGroup } from "react-bootstrap";
import getMessage from "../../utils/getMessage";

const Contact = () => {
  const { register, handleSubmit, reset, setValue } = useForm();

  const [show, setShow] = useState({ isShow: false, id: 0 });
  const [data, setData] = useState([]);

  const [isBtnActive, setIsBtnActive] = useState(false);
  const [rowSelectionIdx, setRowSelectionIdx] = useState({});
  const tableInstanceRef = useRef();
  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        id: "name", //id is still required when using accessorFn instead of accessorKey
        header: "الاسم",
        size: 250,
        Cell: ({ row }) => (
          <Box className="text-end">
            <span>{row.original?.name}</span>
          </Box>
        ),
      },
      {
        accessorKey: "email",
        id: "email", //id is still required when using accessorFn instead of accessorKey
        header: "البريد الالكتروني",
        size: 250,
        Cell: ({ row }) => (
          <Box className="text-end">
            <a
              className="text-decoration-none"
              style={{ color: "#007bff" }}
              href={`mailto:${row.original?.email}`}
            >
              {row.original?.email}
            </a>
          </Box>
        ),
      },
      // {
      //   accessorKey: "status",
      //   id: "status", //id is still required when using accessorFn instead of accessorKey
      //   header: "الحالة",
      //   Cell: ({ row }) => (
      //     <Box
      //       className={`w-fit text-center px-2 py-1 rounded-3 m-auto ${
      //         row.original?.status === "مقروءة" ? "bg-movee" : "bg-danger"
      //       }`}
      //     >
      //       <span className="text-white">{row.original?.status}</span>
      //     </Box>
      //   ),
      // },
      {
        id: "createdAt", //id is still required when using accessorFn instead of accessorKey
        header: "انشأ بتاريخ",
        Cell: ({ row }) => (
          <Box className="text-end">
            <span>{moment(row.original?.createdAt).fromNow()}</span>
          </Box>
        ),
      },
    ],
    []
  );
  const openModal = (id) => {
    setShow({ isShow: true, id });
  };
  const closeModal = () => {
    setShow({ isShow: false, id: 0 });

    reset({
      ar_name: "",
    });
  };
  const getRowsIds = useCallback(() => {
    const rowSelectionIds = Object.keys(rowSelectionIdx || {}).map((idx) => {
      return data[idx]?.id;
    });

    return rowSelectionIds;
  }, [data, rowSelectionIdx]);
  const saveOrder = async () => {
    const token = cookie.load("token");
    const dataWithSeq = data?.map((item, idx) => ({ ...item, seq: idx + 1 }));
    console.log(dataWithSeq);
    try {
      await API.post(`/admin/reorderData?token=${token}`, {
        table_name: "contacts",
        data: dataWithSeq,
      });
      getMessage("success", "تم حفظ الترتيب");

      getData();
    } catch (error) {
      console.error("Error saving order:", error);
      getMessage("error", "حدث خطأ أثناء حفظ الترتيب");
    } finally {
      closeModal();
    }
  };
  const getData = () => {
    const token = cookie.load("token");

    API(`/admin/contact?token=${token}`).then(({ data }) => {
      const collectedData = data?.Data?.map((message) => ({
        id: message?.id,
        name: message?.name,
        email: message?.email,
        message: message?.message,
        status: message?.read ? "مقروءة" : "جديد",
        createdAt: message?.created_at,
        seq: message?.seq, // Include the seq attribute in the mapped object
      }));

      // Sort the collectedData array based on the 'seq' value
      collectedData.sort((a, b) => a.seq - b.seq);

      setData(collectedData);
    });
  };

  const deleteRows = () => {
    const rowsIds = getRowsIds();

    if (!rowsIds?.length) {
      alert("لم تحدد اي عنصر");
    }

    const deleteCallBack = async () => {
      const token = cookie.load("token");

      await API(`/admin/deleteMessage?token=${token}&id=${rowsIds}`);
      getData();
      setRowSelectionIdx({});
    };

    getCheckModal({
      title: "هل انت متاكد من انك تريد حذف العناصر المحدده",
      type: "warning",
      confirmButtonText: "نعم حذف",
      isConfirmedMsg: "تم الحذف بنجاح",
      cb: deleteCallBack,
    });
  };

  useEffect(() => {
    const rowsIds = getRowsIds();

    if (rowsIds?.length) {
      setIsBtnActive(true);
    } else {
      setIsBtnActive(false);
    }
  }, [getRowsIds, rowSelectionIdx]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <FullLayout
      title="رسائل التواصل"
      renderButtons={() => (
        <ButtonGroup className="ms-4">
          <Button
            onClick={deleteRows}
            disabled={!isBtnActive}
            variant="danger"
            className="flex-center text-white"
          >
            <i className="bi bi-trash3" />
            <span className="ms-1">حذف</span>
          </Button>
          <Button
            onClick={saveOrder}
            variant="primary"
            className="flex-center text-white ms-4"
          >
            <AiOutlinePlus size={20} />
            <span className="ms-1">حفظ الترتيب</span>
          </Button>
        </ButtonGroup>
      )}
    >
      <div style={{ maxHeight: 800, maxWidth: 1200, overflow: "auto" }}>
        <MaterialReactTable
          enableRowOrdering
          enableSorting={false}
          enablePagination={false}
          pagination={false}
          onRowSelectionChange={setRowSelectionIdx}
          columns={columns}
          data={data}
          state={{ rowSelection: rowSelectionIdx }}
          initialState={{ showColumnFilters: true }}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          enableColumnActions={false}
          enableBottomToolbar={false}
          enableColumnOrdering
          enableGrouping
          enablePinning
          enableRowSelection
          positionToolbarAlertBanner="bottom"
          tableInstanceRef={tableInstanceRef}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                data.splice(
                  hoveredRow.index,
                  0,
                  data.splice(draggingRow.index, 1)[0]
                );
                setData([...data]);
              }
            },
          })}
          renderDetailPanel={({ row }) => (
            <Box className="text-end">
              <p className="fs-4">الرساله:</p>

              <span style={{ whiteSpace: "pre-wrap" }} className="fs-5">
                {row.original.message}
              </span>
            </Box>
          )}
        />
      </div>
    </FullLayout>
  );
};

export default Contact;
