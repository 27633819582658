import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// layout
import FullLayout from "../../layouts/FullLayout";

//MRT Imports
import MaterialReactTable from "material-react-table";

//Material-UI Imports
import { Box } from "@mui/material";

import { getCheckModal } from "../../utils/getModal";

// icons
import { AiOutlinePlus } from "react-icons/ai";

// components
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  FormGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { Label } from "reactstrap";
import { useForm } from "react-hook-form";

// requests
import API from "../../utils/API";
import cookie from "react-cookies";

import moment from "moment";
import getMessage from "../../utils/getMessage";

const Notifications = () => {
  const { handleSubmit, register, reset } = useForm();

  const [show, setShow] = useState({ isShow: false, id: 0 });
  const [data, setData] = useState([]);

  const [isBtnActive, setIsBtnActive] = useState(false);
  const [rowSelectionIdx, setRowSelectionIdx] = useState({});
  const tableInstanceRef = useRef();

  const openModal = (id) => {
    setShow({ isShow: true, id });
  };
  const closeModal = () => {
    setShow({ isShow: false, id: 0 });
    reset({
      ar_message: "",
      messageTo: "",
    });
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "message",
        id: "message",
        header: "رسالة",
        Cell: ({ row }) => (
          <Box className="text-end">
            <span>{row.original?.message}</span>
          </Box>
        ),
      },
      {
        accessorKey: "to",
        id: "to",
        header: "الي",
        Cell: ({ row }) => (
          <Box className="text-end">
            <span>{row.original?.messageTo === 0 ? "مستخدمين" : "شيوخ"}</span>
          </Box>
        ),
      },
      {
        id: "createdAt",
        header: "انشأ بتاريخ",
        Cell: ({ row }) => (
          <Box className="text-end">
            <span>{moment(row.original?.createdAt).fromNow()}</span>
          </Box>
        ),
      },
    ],
    []
  );

  const getRowsIds = useCallback(() => {
    const rowSelectionIds = Object.keys(rowSelectionIdx || {}).map((idx) => {
      return data[idx]?.id;
    });

    return rowSelectionIds;
  }, [data, rowSelectionIdx]);
  const deleteRows = () => {
    const rowsIds = getRowsIds();

    if (!rowsIds?.length) {
      alert("لم تحدد اي عنصر");
    }

    const deleteCallBack = async () => {
      const formData = new FormData();
      const token = cookie.load("token");

      formData.append("token", token);
      formData.append("id", rowsIds);

      await API.post(`/admin/Deletenotifications`, formData);
      setRowSelectionIdx({});
      getData();
    };

    getCheckModal({
      title: "هل انت متاكد من انك تريد حذف الاشعارات المحدده",
      type: "warning",
      confirmButtonText: "نعم حذف",
      isConfirmedMsg: "تم الحذف بنجاح",
      cb: deleteCallBack,
    });
  };
  const getData = () => {
    const token = cookie.load("token");

    API(`/admin/notifications?token=${token}`).then(({ data }) => {
      setData(
        data?.Data?.map((notification, idx) => ({
          id: notification?.id,
          message: notification?.message,
          messageTo: notification?.messageTo,
          createdAt: notification?.created_at,
        }))
      );
    });
  };
  const saveHandelar = async (data) => {
    const formData = new FormData();
    const token = cookie.load("token");

    formData.append("token", token);
    formData.append("message_ar", data?.ar_message);
    formData.append("message_en", data?.ar_message);
    formData.append("type", data?.messageTo);

    await API.post(`/admin/Createnotifications`, formData)
      .then(({ data }) => {
        if (data?.code != 200) {
          getMessage("error", "يرجي ملي جميع الحقول");
        }
      })
      .catch(() => {
        getMessage("error", "يرجي ملي جميع الحقول");
      });
    getData();
    closeModal();
  };

  useEffect(() => {
    const rowsIds = getRowsIds();

    if (rowsIds?.length) {
      setIsBtnActive(true);
    } else {
      setIsBtnActive(false);
    }
  }, [getRowsIds, rowSelectionIdx]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <FullLayout
      title="الاشعارات"
      renderButtons={() => (
        <div className="flex-center">
          <ButtonGroup className="ms-4">
            <Button
              onClick={deleteRows}
              disabled={!isBtnActive}
              variant="danger"
              className="flex-center text-white"
            >
              <i className="bi bi-trash3" />
              <span className="ms-1">حذف</span>
            </Button>
          </ButtonGroup>

          <Button
            onClick={() => openModal(0)}
            variant="primary"
            className="flex-center text-white"
          >
            <AiOutlinePlus size={20} />
            <span className="ms-1">اضافه</span>
          </Button>
        </div>
      )}
    >
      <div style={{ maxHeight: 800, maxWidth: 1200, overflow: "auto" }}>
        <MaterialReactTable
          enablePagination={false}
          pagination={false}
          onRowSelectionChange={setRowSelectionIdx}
          columns={columns}
          data={data}
          state={{ rowSelection: rowSelectionIdx }}
          initialState={{ showColumnFilters: true }}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          enableColumnActions={false}
          enableBottomToolbar={false}
          enableColumnOrdering
          enableGrouping
          enablePinning
          enableRowSelection
          positionToolbarAlertBanner="bottom"
          tableInstanceRef={tableInstanceRef}
        />
      </div>

      {/* Edit Modal or Add */}
      <Modal centered show={show.isShow} onHide={closeModal}>
        <Modal.Header>
          <Modal.Title>اضافة او تعديل الاشعارات</Modal.Title>

          <button className="btn-close me-auto ms-0" onClick={closeModal} />
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Label for="messageTo">الي</Label>
            <Form.Control id="messageTo" as="select" {...register("messageTo")}>
              <option value="0">المستخدمين</option>
              <option value="1">الشيوخ</option>
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Row className="mb-3">
              <Col lg="12">
                <FormGroup>
                  <Label for="ar_message">الرسالة</Label>
                  <Form.Control
                    id="ar_message"
                    type="text"
                    as="textarea"
                    rows={6}
                    {...register("ar_message")}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="flex-center">
          <Button variant="primary" onClick={handleSubmit(saveHandelar)}>
            حفظ
          </Button>

          <Button variant="danger" onClick={closeModal}>
            الغاء
          </Button>
        </Modal.Footer>
      </Modal>
    </FullLayout>
  );
};

export default Notifications;
