import LogoDark from "../../assets/images/logos/monsterlogo.png";
import { Link } from "react-router-dom";

const Logo = ({ width, height }) => {
  return (
    <Link to="/">
      <span>
        <img src={LogoDark} width={width} height={height} alt="logo" />
      </span>
    </Link>
  );
};

export default Logo;
