import React from "react";

import FullLayout from "../../layouts/FullLayout";
import WorksRequests from "./WorksRequests";
import QuestionsRequests from "./QuestionsRequests";

const UsersRequests = () => {
  return (
    <FullLayout title="طلبات المستخدمين">
      <QuestionsRequests />
      <WorksRequests />
    </FullLayout>
  );
};

export default UsersRequests;
