import React, { useCallback, useEffect, useState } from "react";
import FullLayout from "../../layouts/FullLayout";

import default_img from "../../assets/images/users/default_imgs.jpeg";

import {
  Card,
  Row,
  Col,
  CardTitle,
  CardBody,
  FormGroup,
  Label,
} from "reactstrap";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";

// cookies
import cookie from "react-cookies";

// apis
import API from "../../utils/API";
import getMessage from "../../utils/getMessage";

const Settings = () => {
  const { register, handleSubmit, watch, setValue } = useForm();
  const [imgPreview, setImgPreview] = useState({ img: "", logo: "" });

  const saveHandelar = (data) => {
    const token = cookie.load("token");
    const formData = new FormData();

    Object.keys(data)?.map((key) => {
      if (key == "logo" || key == "image" || key == "img") return;

      formData.append(key, data[key]);
    });
    formData.append("title_ar", data?.title);
    formData.append("whatsapp", "00");
    data?.image?.[0] && formData.append("image", data?.image[0]);
    data?.logo?.[0] && formData.append("logo", data?.logo[0]);
    formData.append("token", token);

    API.post(`/admin/UpdateSettings`, formData)
      .then(({ data }) => {
        if (data?.code != 200) {
          getMessage("error", "يرجي ملي جميع الحقول");
        }

        if (data.code == 200) {
          getMessage("success", "تم الحفظ");
        }
      })
      .catch(() => {
        getMessage("error", "يرجي ملي جميع الحقول");
      });
  };

  const openFile = async (file) => {
    return new Promise((res) => {
      const reader = new FileReader();
      reader.onload = ({ target }) => {
        res(target?.result);
      };
      reader.readAsDataURL(file);
    });
  };

  const getData = useCallback(() => {
    const token = cookie.load("token");

    API(`/admin/settings?token=${token}`).then(async ({ data }) => {
      const settingsData = data?.Data;
      const collectedData = {
        title: settingsData.title,
        description: settingsData.description,
        app_store_url: settingsData.app_store_url,
        play_store_url: settingsData.play_store_url,
        info_email: settingsData.info_email,
        paginate: settingsData.paginate,
        logo: settingsData.logo,
        image: settingsData.image,
      };

      Object.keys(collectedData)?.map((key) => {
        if (key === "image" || key === "logo") return;

        setValue(key, collectedData[key]);
      });

      setImgPreview({ img: collectedData?.image, logo: collectedData?.logo });
    });
  }, [setValue]);

  useEffect(() => {
    const getData = async () => {
      if (watch("image")?.length) {
        const data = await openFile(watch("image")[0]);

        setImgPreview((prev) => {
          if (prev.img === data) {
            return prev;
          } else {
            return { ...prev, img: data };
          }
        });
      }

      if (watch("logo")?.length) {
        const data = await openFile(watch("logo")[0]);

        setImgPreview((prev) => {
          if (prev.logo === data) {
            return prev;
          } else {
            return { ...prev, logo: data };
          }
        });
      }
    };

    getData();
    // if (watch("img")?.length || watch("logo")?.length) {
    // }
  });

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <FullLayout
      title="الاعدادات العامة"
      renderButtons={() => (
        <button
          onClick={handleSubmit(saveHandelar)}
          className="btn btn-primary"
        >
          حفظ
        </button>
      )}
    >
      <form onSubmit={handleSubmit(saveHandelar)}>
        <Card className="shadow-sm border">
          <CardTitle tag="h4" className="border-bottom text-dark p-3 mb-0">
            البيانات الرئيسية
          </CardTitle>
          <CardBody>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <Label for="title">الاسم</Label>
                  <Form.Control id="title" type="text" {...register("title")} />
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <Label for="description">الوصف</Label>
                  <Form.Control
                    id="description"
                    type="textarea"
                    rows={6}
                    {...register("description")}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className="shadow-sm border">
          <CardTitle tag="h4" className="border-bottom text-dark p-3 mb-0">
            معلومات اخرى
          </CardTitle>
          <CardBody>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <Label for="play_store_url">رابط متجر ابل</Label>
                  <Form.Control
                    id="play_store_url"
                    type="text"
                    {...register("play_store_url")}
                  />
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <Label for="app_store_url">رابط متجر جوجل</Label>
                  <Form.Control
                    id="app_store_url"
                    type="text"
                    {...register("app_store_url")}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg="6">
                <FormGroup>
                  <Label for="info_email">البريد الالكتروني</Label>
                  <Form.Control
                    id="info_email"
                    type="text"
                    {...register("info_email")}
                  />
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <Label for="paginate">السجلات في كل صفحة</Label>
                  <Form.Control
                    id="paginate"
                    type="text"
                    {...register("paginate")}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Row>
          <Col lg="6">
            <Label for="logo">شعار</Label>
            <input type="file" id="logo" hidden {...register("logo")} />

            <Label for="logo" className="cu-pointer position-relative d-block">
              <i
                className="bi bi-pencil-fill fs-4 position-absolute"
                style={{ top: -10, right: -5 }}
              />

              <img
                className="w-100"
                src={imgPreview.logo || default_img}
                alt=""
              />
            </Label>
          </Col>

          <Col lg="6">
            <Label for="image">صورة</Label>
            <input type="file" id="image" hidden {...register("image")} />

            <Label for="image" className="cu-pointer position-relative d-block">
              <i
                className="bi bi-pencil-fill fs-4 position-absolute"
                style={{ top: -10, right: -5 }}
              />

              <img
                className="w-100"
                src={imgPreview.img || default_img}
                alt=""
              />
            </Label>
          </Col>
        </Row>
      </form>
    </FullLayout>
  );
};

export default Settings;
