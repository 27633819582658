import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// layout
import FullLayout from "../../layouts/FullLayout";

//MRT Imports
import MaterialReactTable from "material-react-table";

//Material-UI Imports
import { Box, IconButton } from "@mui/material";

import { getCheckModal } from "../../utils/getModal";

// icons
import { MdNotInterested } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";

// components
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  FormGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { Label } from "reactstrap";

// import { data as collectedData } from "./makeData";
import { useForm } from "react-hook-form";

import default_img from "../../assets/images/users/user5.jpg";

// cookies
import cookie from "react-cookies";

// apis
import API from "../../utils/API";
import axios from "axios";

import moment from "moment";
import getMessage from "../../utils/getMessage";

const Countries = () => {
  const { handleSubmit, register, watch, setValue, reset } = useForm();

  const [preview, setPreview] = useState("");
  const [show, setShow] = useState({ isShow: false, id: 0 });
  const [data, setData] = useState([]);
  const [isBtnActive, setIsBtnActive] = useState(false);
  const [rowSelectionIdx, setRowSelectionIdx] = useState({});
  const [currentCountry, setCurrentCountry] = useState({});

  const tableInstanceRef = useRef();

  const openModal = (id) => {
    setShow({ isShow: true, id });
    setCurrentCountry({});
    if (id === 0) {
      reset({
        flag: [],
        ar_name: "",
        code: "",
        currency: "",
      });
    }
  };
  const closeModal = () => {
    setShow({ isShow: false, id: 0 });

    reset({
      flag: [],
      ar_name: "",
      code: "",
      currency: "",
    });
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        id: "name",
        header: "الاسم",
        size: 250,
        Cell: ({ row }) => (
          <Box className="text-end d-flex align-items-center">
            <img
              style={{ width: 50, height: 50, borderRadius: "100%" }}
              src={row.original?.icon}
              alt=""
            />

            <span className="ms-3">{row.original?.name}</span>
          </Box>
        ),
      },
      {
        accessorKey: "code",
        id: "code",
        header: "الكود",
        size: 100,
        Cell: ({ row }) => (
          <Box className="text-end d-flex align-items-center">
            <span>{row.original?.code}</span>
          </Box>
        ),
      },
      {
        accessorKey: "currency",
        id: "currency",
        header: "كود الدوله",
        size: 100,
        Cell: ({ row }) => (
          <Box className="text-end d-flex align-items-center">
            <span>{row.original?.currency}</span>
          </Box>
        ),
      },
      {
        accessorKey: "status",
        id: "status",
        header: "الحالة",
        size: 50,
        Cell: ({ row }) => (
          <Box
            className={`w-fit text-center px-2 py-1 rounded-3 m-auto ${
              row.original?.status === "فعال" ? "bg-movee" : "bg-danger"
            }`}
          >
            <span className="text-white">{row.original?.status}</span>
          </Box>
        ),
      },
      {
        id: "createdAt",
        header: "انشأ بتاريخ",
        Cell: ({ row }) => (
          <Box className="text-end">
            <span>{moment(row.original?.createdAt).fromNow()}</span>
          </Box>
        ),
      },
      {
        id: "actions",
        header: "العمليات",
        Cell: ({ row }) => (
          <Box className="text-end">
            <IconButton onClick={() => openModal(row.original.id)}>
              <i className="bi bi-pencil-square fs-5"></i>
            </IconButton>
          </Box>
        ),
      },
    ],
    []
  );

  const getData = () => {
    const token = cookie.load("token");

    API(`admin/countries?token=${token}`).then(({ data }) => {
      const collectedData = data?.data?.map((country) => ({
        id: country?.id,
        name: country?.name,
        code: country?.code,
        currency: country?.currency_code,
        icon: country?.flag,
        status: country?.status === "active" ? "فعال" : "غير فعال",
        createdAt: country?.created_at,
        seq: country?.seq,
      }));

      // Sort the collectedData array based on the seq field
      collectedData.sort((a, b) => a.seq - b.seq);
      setData(collectedData);
    });
  };
  const getRowsIds = useCallback(() => {
    const rowSelectionIds = Object.keys(rowSelectionIdx || {}).map((idx) => {
      return data[idx]?.id;
    });

    return rowSelectionIds;
  }, [data, rowSelectionIdx]);
  const deleteRows = () => {
    const rowsIds = getRowsIds();

    if (!rowsIds?.length) {
      alert("لم تحدد اي عنصر");
    }

    const deleteCallBack = async () => {
      const formData = new FormData();
      const token = cookie.load("token");

      formData.append("token", token);
      formData.append("id", rowsIds);
      formData.append("event", "delete");

      await API.post(`/admin/CountriesStatus`, formData);
      getData();
      setRowSelectionIdx({});
    };

    getCheckModal({
      title: "هل انت متاكد من انك تريد حذف الدول المحدده",
      type: "warning",
      confirmButtonText: "نعم حذف",
      isConfirmedMsg: "تم الحذف بنجاح",
      cb: deleteCallBack,
    });
  };
  const activeSelection = () => {
    const rowsIds = getRowsIds();

    if (!rowsIds?.length) {
      alert("لم تحدد اي عنصر");
    }

    const deleteCallBack = async () => {
      const formData = new FormData();
      const token = cookie.load("token");

      formData.append("token", token);
      formData.append("id", rowsIds);
      formData.append("event", "active");

      await API.post(`/admin/CountriesStatus`, formData);
      getData();
      setRowSelectionIdx({});
    };

    getCheckModal({
      title: "هل انت متاكد من انك تريد تفعيل الدول المحددين",
      type: "warning",
      confirmButtonText: "نعم تفعيل",
      isConfirmedMsg: "تم التفعيل بنجاح",
      cb: deleteCallBack,
    });
  };
  const disActiveSelection = () => {
    const rowsIds = getRowsIds();

    if (!rowsIds?.length) {
      alert("لم تحدد اي عنصر");
    }

    const deleteCallBack = async () => {
      const formData = new FormData();
      const token = cookie.load("token");

      formData.append("token", token);
      formData.append("id", rowsIds);
      formData.append("event", "not_active");

      await API.post(`/admin/CountriesStatus`, formData);
      getData();
      setRowSelectionIdx({});
    };

    getCheckModal({
      title: "هل انت متاكد من انك تريد تعطيل الدول المحددين",
      type: "warning",
      confirmButtonText: "نعم تعطيل",
      isConfirmedMsg: "تم التعطيل بنجاح",
      cb: deleteCallBack,
    });
  };
  const saveOrder = async () => {
    const token = cookie.load("token");
    const dataWithSeq = data?.map((item, idx) => ({ ...item, seq: idx + 1 }));
    console.log(dataWithSeq);
    try {
      await API.post(`/admin/reorderNestedData?token=${token}`, {
        main_table_name: "cities",
        secondary_table_name: "city_translations",
        key: "city_id",
        data: dataWithSeq,
      });
      getMessage("success", "تم حفظ الترتيب");

      getData();
    } catch (error) {
      console.error("Error saving order:", error);
      getMessage("error", "حدث خطأ أثناء حفظ الترتيب");
    } finally {
      closeModal();
    }
  };
  const saveHandelar = async (data) => {
    const token = cookie.load("token");
    const formData = new FormData();

    show?.id && formData.append("id", show?.id);
    data?.flag?.[0] && formData.append("image", data?.flag?.[0]);
    formData.append("token", token);
    formData.append("name_ar", data?.ar_name);
    formData.append("code", data?.code);
    formData.append("currency_code", data?.currency);

    await API.post(
      show?.id ? "/admin/updateCountries" : "/admin/createCountries",
      formData
    )
      .then(({ data }) => {
        if (data?.code != 200) {
          getMessage("error", "يرجي ملي جميع الحقول");
        }
      })
      .catch(() => {
        getMessage("error", "يرجي ملي جميع الحقول");
      });
    getData();
    closeModal();
  };
  const openFile = async (file) => {
    return new Promise((res) => {
      const reader = new FileReader();
      reader.onload = ({ target }) => {
        res(target?.result);
      };
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    const rowsIds = getRowsIds();

    if (rowsIds?.length) {
      setIsBtnActive(true);
    } else {
      setIsBtnActive(false);
    }
  }, [getRowsIds, rowSelectionIdx]);

  useEffect(() => {
    const getData = async () => {
      if (watch("flag")?.length) {
        const data = await openFile(watch("flag")[0]);

        setPreview((prev) => {
          return prev === data ? prev : data;
        });
      } else {
        setPreview("");
      }
    };

    getData();
  });

  useEffect(() => {
    getData();
  }, []);

  // get data
  useEffect(() => {
    if (show?.id) {
      const currentCountry = data?.find((banner) => banner.id === show.id);

      setValue("ar_name", currentCountry?.name);
      setValue("code", currentCountry?.code);
      setValue("currency", currentCountry?.currency);
      // Check if the image exists in currentBanner
      if (currentCountry?.icon) {
        setValue("flag", currentCountry?.icon);
        setCurrentCountry(currentCountry); // Move this line here
      } else {
        axios
          .get(currentCountry?.icon, { responseType: "blob" })
          .then((res) => {
            const blob = res.data;
            const file = new File([blob], "image.jpg", {
              type: "image/jpeg",
            });
            setValue("flag", [file]);
          });
        console.log(currentCountry);
      }
    }
  }, [data, show.id, setValue]);

  return (
    <FullLayout
      title="الدول"
      renderButtons={() => (
        <div className="flex-center">
          <ButtonGroup className="ms-4">
            <Button
              onClick={activeSelection}
              disabled={!isBtnActive}
              variant="primary"
              className="flex-center text-white"
            >
              <i className="bi bi-check2 fs-5 flex-center" />
              <span className="ms-1">فعال</span>
            </Button>

            <Button
              onClick={disActiveSelection}
              disabled={!isBtnActive}
              variant="warning"
              className="flex-center text-white"
            >
              <MdNotInterested size={20} />
              <span className="ms-1">غير فعال</span>
            </Button>

            <Button
              onClick={deleteRows}
              disabled={!isBtnActive}
              variant="danger"
              className="flex-center text-white"
            >
              <i className="bi bi-trash3" />
              <span className="ms-1">حذف</span>
            </Button>
          </ButtonGroup>

          <Button
            onClick={() => openModal(0)}
            variant="primary"
            className="flex-center text-white"
          >
            <AiOutlinePlus size={20} />
            <span className="ms-1">اضافه</span>
          </Button>

          <Button
            onClick={saveOrder}
            variant="primary"
            className="flex-center text-white ms-4"
          >
            <AiOutlinePlus size={20} />
            <span className="ms-1">حفظ الترتيب</span>
          </Button>
        </div>
      )}
    >
      <div style={{ maxHeight: 800, maxWidth: 1200, overflow: "auto" }}>
        <MaterialReactTable
          enablePagination={false}
          onRowSelectionChange={setRowSelectionIdx}
          columns={columns}
          data={data}
          state={{ rowSelection: rowSelectionIdx }}
          initialState={{ showColumnFilters: true }}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          pagination={false}
          enableColumnActions={false}
          enableBottomToolbar={false}
          enableColumnOrdering
          enableGrouping
          enablePinning
          enableRowSelection
          enableRowOrdering
          enableSorting={false}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                data.splice(
                  hoveredRow.index,
                  0,
                  data.splice(draggingRow.index, 1)[0]
                );
                setData([...data]);
              }
            },
          })}
          positionToolbarAlertBanner="bottom"
          tableInstanceRef={tableInstanceRef}
        />
      </div>

      {/* Edit Modal or Add */}
      <Modal centered show={show.isShow} onHide={closeModal}>
        <Modal.Header>
          <Modal.Title>اضافة او تعديل الدول</Modal.Title>

          <button className="btn-close me-auto ms-0" onClick={closeModal} />
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Row className="mb-5">
              <Label>علم الدوله</Label>

              <div className="imgPlaceholder flex-center">
                <label
                  htmlFor="img"
                  style={{
                    width: 80,
                    height: 80,
                    cursor: "pointer",
                    borderRadius: "50%",
                    overflow: "hidden",
                  }}
                >
                  <img
                    className="w-100 h-100"
                    src={preview ? preview : currentCountry.icon || default_img}
                    alt=""
                  />
                </label>
              </div>

              <input
                type="file"
                name="img"
                id="img"
                hidden
                {...register("flag")}
              />
            </Row>
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Row className="mb-3">
              <FormGroup>
                <Label for="ar_name">الاسم</Label>
                <Form.Control
                  id="ar_name"
                  type="text"
                  {...register("ar_name")}
                />
              </FormGroup>
            </Row>
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Row className="mb-3">
              <Col lg="6">
                <FormGroup>
                  <Label for="code">الكود</Label>
                  <Form.Control id="code" type="text" {...register("code")} />
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <Label for="currency">كود الدوله</Label>
                  <Form.Control
                    id="currency"
                    type="text"
                    {...register("currency")}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="flex-center">
          <Button variant="primary" onClick={handleSubmit(saveHandelar)}>
            حفظ
          </Button>

          <Button variant="danger" onClick={closeModal}>
            الغاء
          </Button>
        </Modal.Footer>
      </Modal>
    </FullLayout>
  );
};

export default Countries;
