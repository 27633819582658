import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

//MRT Imports
import MaterialReactTable from "material-react-table";

//Material-UI Imports
import { Box, IconButton } from "@mui/material";

import { getCheckModal } from "../../../utils/getModal";

// icons
import { MdNotInterested } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";

// components
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  FormGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { Label } from "reactstrap";

// import { data as collectedData } from "./makeData";
import { useForm } from "react-hook-form";

// cookies
import cookie from "react-cookies";

// apis
import API from "../../../utils/API";

import moment from "moment";
import getMessage from "../../../utils/getMessage";

const worksSectionsId = 2;

const PracticalMessagesItems = () => {
  const { handleSubmit, register, reset, setValue } = useForm();

  const [show, setShow] = useState({ isShow: false, id: 0 });
  const [data, setData] = useState([]);
  const [isBtnActive, setIsBtnActive] = useState(false);
  const [rowSelectionIdx, setRowSelectionIdx] = useState({});
  const tableInstanceRef = useRef();

  const [sections, setSections] = useState([]);
  const [resorces, setResorces] = useState([]);

  const openModal = (id) => {
    setShow({ isShow: true, id });
  };
  const closeModal = () => {
    setShow({ isShow: false, id: 0 });

    reset({
      ar_title: "",
      ar_content: "",
    });
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "ar_name",
        id: "ar_name",
        header: "العنوان",
        size: 250,
        Cell: ({ row }) => (
          <Box className="text-end">
            <span>{row.original?.ar_name}</span>
          </Box>
        ),
      },
      {
        accessorKey: "reference",
        id: "reference",
        header: "المرجع",
        size: 100,
        Cell: ({ row }) => (
          <Box className="text-end">
            <span>{row.original?.reference}</span>
          </Box>
        ),
      },
      {
        accessorKey: "status",
        id: "status",
        header: "الحالة",
        size: 50,
        Cell: ({ row }) => (
          <Box
            className={`w-fit text-center px-2 py-1 rounded-3 m-auto ${
              row.original?.status === "فعال" ? "bg-movee" : "bg-danger"
            }`}
          >
            <span className="text-white">{row.original?.status}</span>
          </Box>
        ),
      },
      {
        id: "createdAt",
        header: "انشأ بتاريخ",
        Cell: ({ row }) => (
          <Box className="text-end">
            <span>{moment(row.original?.createdAt).fromNow()}</span>
          </Box>
        ),
      },
      {
        id: "actions",
        header: "العمليات",
        Cell: ({ row }) => (
          <Box className="text-end">
            <IconButton onClick={() => openModal(row.original.id)}>
              <i className="bi bi-pencil-square fs-5"></i>
            </IconButton>
          </Box>
        ),
      },
    ],
    []
  );
  const getData = useCallback(async () => {
    const token = cookie.load("token");

    API(`/admin/practical_messages?token=${token}`).then(({ data }) => {
      const collectedData = data?.Data?.map((data) => ({
        id: data?.id,
        ar_name: data?.title_ar,
        reference: resorces?.find(
          (reference) => reference?.id == data?.reference_id
        )?.name,
        section_id: data?.section_id,
        ar_content: data?.ar,
        status: data?.status === "active" ? "فعال" : "غير فعال",
        createdAt: data?.created_at,
        seq: data?.seq,
      }));

      collectedData.sort((a, b) => a.seq - b.seq);

      setData(collectedData);
    });

    API(`/admin/sections?token=${token}&sub_to=${worksSectionsId}`).then(
      ({ data }) => {
        const collectedData = data?.Data?.map((section) => ({
          id: section?.id,
          name: section?.name,
        }));

        setSections(collectedData);
      }
    );

    API(`/admin/references?token=${token}`).then(({ data }) => {
      const collectedData = data?.Data?.map((data) => ({
        id: data?.id,
        name: data?.name,
      }));

      if (resorces?.length) return;
      setResorces(collectedData);
    });
  }, [resorces]);

  const getRowsIds = useCallback(() => {
    const rowSelectionIds = Object.keys(rowSelectionIdx || {}).map((idx) => {
      return data[idx]?.id;
    });

    return rowSelectionIds;
  }, [data, rowSelectionIdx]);
  const saveOrder = async () => {
    const token = cookie.load("token");
    const dataWithSeq = data?.map((item, idx) => ({ ...item, seq: idx + 1 }));
    console.log(dataWithSeq);
    try {
      await API.post(`/admin/reorderData?token=${token}`, {
        table_name: "practical_messages",
        data: dataWithSeq,
      });
      getMessage("success", "تم حفظ الترتيب");

      getData();
    } catch (error) {
      console.error("Error saving order:", error);
      getMessage("error", "حدث خطأ أثناء حفظ الترتيب");
    } finally {
      closeModal();
    }
  };
  const deleteRows = () => {
    const rowsIds = getRowsIds();

    if (!rowsIds?.length) {
      alert("لم تحدد اي عنصر");
    }

    const deleteCallBack = async () => {
      const formData = new FormData();
      const token = cookie.load("token");

      formData.append("token", token);
      formData.append("id", rowsIds);
      formData.append("event", "delete");

      await API.post(`/admin/practical_messages/Changestatus`, formData);
      getData();
      setRowSelectionIdx({});
    };

    getCheckModal({
      title: "هل انت متاكد من انك تريد حذف الرسائل المحدده",
      type: "warning",
      confirmButtonText: "نعم حذف",
      isConfirmedMsg: "تم الحذف بنجاح",
      cb: deleteCallBack,
    });
  };
  const activeSelection = () => {
    const rowsIds = getRowsIds();

    if (!rowsIds?.length) {
      alert("لم تحدد اي عنصر");
    }

    const deleteCallBack = async () => {
      const formData = new FormData();
      const token = cookie.load("token");

      formData.append("token", token);
      formData.append("id", rowsIds);
      formData.append("event", "active");

      await API.post(`/admin/practical_messages/Changestatus`, formData);
      getData();
      setRowSelectionIdx({});
    };

    getCheckModal({
      title: "هل انت متاكد من انك تريد تفعيل الرسائل المحدده",
      type: "warning",
      confirmButtonText: "نعم تفعيل",
      isConfirmedMsg: "تم التفعيل بنجاح",
      cb: deleteCallBack,
    });
  };
  const disActiveSelection = () => {
    const rowsIds = getRowsIds();

    if (!rowsIds?.length) {
      alert("لم تحدد اي عنصر");
    }

    const deleteCallBack = async () => {
      const formData = new FormData();
      const token = cookie.load("token");

      formData.append("token", token);
      formData.append("id", rowsIds);
      formData.append("event", "not_active");

      await API.post(`/admin/practical_messages/Changestatus`, formData);
      getData();
      setRowSelectionIdx({});
    };

    getCheckModal({
      title: "هل انت متاكد من انك تريد تعطيل الرسائل المحدده",
      type: "warning",
      confirmButtonText: "نعم تعطيل",
      isConfirmedMsg: "تم التعطيل بنجاح",
      cb: deleteCallBack,
    });
  };
  const saveHandelar = async (data) => {
    const token = cookie.load("token");
    const formData = new FormData();

    show?.id && formData.append("id", show?.id);
    formData.append("token", token);

    formData.append("reference", data?.resorce);
    formData.append("content_ar", data?.ar_content);
    formData.append("title_ar", data?.ar_title);
    formData.append("section_id", data?.section_id);

    await API.post(
      show?.id
        ? "/admin/practical_messages/edit"
        : "/admin/practical_messages_store",
      formData
    )
      .then(({ data }) => {
        if (data?.code != 200) {
          getMessage("error", "يرجي ملي جميع الحقول");
        }
      })
      .catch(() => {
        getMessage("error", "يرجي ملي جميع الحقول");
      });
    getData();
    closeModal();
  };

  useEffect(() => {
    const rowsIds = getRowsIds();

    if (rowsIds?.length) {
      setIsBtnActive(true);
    } else {
      setIsBtnActive(false);
    }
  }, [getRowsIds, rowSelectionIdx]);

  useEffect(() => {
    getData();
  }, [getData]);

  // get data
  useEffect(() => {
    if (show?.id) {
      const currentData = data?.find((data) => data.id === show.id);

      setValue("resorce", currentData?.reference_id);
      setValue("section_id", currentData?.section_id);
      setValue("ar_title", currentData?.ar_name);
      setValue("ar_content", currentData?.ar_content);
    }
  }, [data, show.id, setValue]);

  return (
    <article className="mb-5">
      <h2 className="mb-3">الرسائل العملية</h2>

      <div style={{ maxHeight: 800, maxWidth: 1200, overflow: "auto" }}>
        <MaterialReactTable
          enableRowOrdering
          enableSorting={false}
          enablePagination={false}
          pagination={false}
          onRowSelectionChange={setRowSelectionIdx}
          columns={columns}
          data={data}
          state={{ rowSelection: rowSelectionIdx }}
          initialState={{ showColumnFilters: true }}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          enableColumnActions={false}
          enableBottomToolbar={false}
          enableColumnOrdering
          enableGrouping
          enablePinning
          enableRowSelection
          positionToolbarAlertBanner="bottom"
          tableInstanceRef={tableInstanceRef}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                data.splice(
                  hoveredRow.index,
                  0,
                  data.splice(draggingRow.index, 1)[0]
                );
                setData([...data]);
              }
            },
          })}
          renderTopToolbarCustomActions={() => (
            <div className="flex-center">
              <ButtonGroup className="ms-4">
                <Button
                  onClick={activeSelection}
                  disabled={!isBtnActive}
                  variant="primary"
                  className="flex-center text-white"
                >
                  <i className="bi bi-check2 fs-5 flex-center" />
                  <span className="ms-1">فعال</span>
                </Button>

                <Button
                  onClick={disActiveSelection}
                  disabled={!isBtnActive}
                  variant="warning"
                  className="flex-center text-white"
                >
                  <MdNotInterested size={20} />
                  <span className="ms-1">غير فعال</span>
                </Button>

                <Button
                  onClick={deleteRows}
                  disabled={!isBtnActive}
                  variant="danger"
                  className="flex-center text-white"
                >
                  <i className="bi bi-trash3" />
                  <span className="ms-1">حذف</span>
                </Button>
              </ButtonGroup>

              <Button
                onClick={saveOrder}
                variant="primary"
                className="flex-center text-white ms-4"
              >
                <AiOutlinePlus size={20} />
                <span className="ms-1">حفظ الترتيب</span>
              </Button>

              <Button
                onClick={() => openModal(0)}
                variant="primary"
                className="flex-center text-white"
              >
                <AiOutlinePlus size={20} />
                <span className="ms-1">اضافه</span>
              </Button>
            </div>
          )}
        />
      </div>

      {/* Edit Modal or Add */}
      <Modal
        className="p-0"
        fullscreen
        centered
        show={show.isShow}
        onHide={closeModal}
      >
        <Modal.Header>
          <Modal.Title>اضافة او تعديل الرسائل</Modal.Title>

          <button className="btn-close me-auto ms-0" onClick={closeModal} />
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Row className="mb-3">
              <Col lg="6">
                <FormGroup>
                  <Label for="resorce">المرجع</Label>
                  <Form.Control
                    id="resorce"
                    as="select"
                    {...register("resorce")}
                  >
                    {resorces?.map((resorce, idx) => (
                      <option key={idx} value={resorce?.id}>
                        {resorce?.name}
                      </option>
                    ))}
                  </Form.Control>
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <Label for="section_id">القسم</Label>
                  <Form.Control
                    as="select"
                    id="section_id"
                    {...register("section_id")}
                  >
                    {sections?.map((section, idx) => (
                      <option key={idx} value={section?.id}>
                        {section?.name}
                      </option>
                    ))}
                  </Form.Control>
                </FormGroup>
              </Col>
            </Row>
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Row className="mb-3">
              <FormGroup>
                <Label for="ar_title">العنوان</Label>
                <Form.Control
                  id="ar_title"
                  type="text"
                  {...register("ar_title")}
                />
              </FormGroup>
            </Row>
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Row className="mb-3">
              <Col lg="12" className="mt-3">
                <FormGroup>
                  <Label for="ar_content">المحتوي</Label>
                  <Form.Control
                    id="ar_content"
                    as="textarea"
                    rows={6}
                    {...register("ar_content")}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="flex-center">
          <Button variant="primary" onClick={handleSubmit(saveHandelar)}>
            حفظ
          </Button>

          <Button variant="danger" onClick={closeModal}>
            الغاء
          </Button>
        </Modal.Footer>
      </Modal>
    </article>
  );
};

export default PracticalMessagesItems;
