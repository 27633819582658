import React, { useCallback, useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import FullLayout from "../../layouts/FullLayout";

import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";

// cookies
import cookie from "react-cookies";

// apis
import API from "../../utils/API";
import getMessage from "../../utils/getMessage";

const System_seo = () => {
  const { register, handleSubmit, setValue } = useForm();

  const saveHandelar = (data) => {
    const token = cookie.load("token");
    const formData = new FormData();

    formData.append("token", token);
    formData.append("seo_in_body", data?.seo_in_body);
    formData.append("seo_in_header", data?.seo_in_header);

    API.post(`/admin/UpdateSystem_seo`, formData)
      .then(({ data }) => {
        if (data?.code != 200) {
          getMessage("error", "يرجي ملي جميع الحقول");
        }

        if (data.code == 200) {
          getMessage("success", "تم الحفظ");
        }
      })
      .catch(() => {
        getMessage("error", "يرجي ملي جميع الحقول");
      });
  };

  const getData = useCallback(() => {
    const token = cookie.load("token");

    API(`/admin/system_seo?token=${token}`).then(async ({ data }) => {
      setValue("seo_in_body", data?.Data?.seo_in_body);
      setValue("seo_in_header", data?.Data?.seo_in_header);
    });
  }, [setValue]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <FullLayout
      renderButtons={() => (
        <button
          onClick={handleSubmit(saveHandelar)}
          className="btn btn-primary"
        >
          حفظ
        </button>
      )}
      title="اعدادات Seo"
    >
      <form onSubmit={handleSubmit(saveHandelar)}>
        <Card className="shadow-sm border">
          <CardTitle tag="h4" className="border-bottom text-dark p-3 mb-0">
            اعدادات Seo
          </CardTitle>
          <CardBody>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <Label for="seo_in_body">Seo In Body</Label>
                  <Form.Control
                    id="seo_in_body"
                    as="textarea"
                    {...register("seo_in_body")}
                    rows={6}
                  />
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <Label for="seo_in_header">Seo In Header</Label>
                  <Form.Control
                    id="seo_in_header"
                    as="textarea"
                    {...register("seo_in_header")}
                    rows={6}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </form>
    </FullLayout>
  );
};

export default System_seo;
