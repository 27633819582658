import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

// layout
import FullLayout from "../../layouts/FullLayout";

//MRT Imports
import MaterialReactTable from "material-react-table";

//Material-UI Imports
import { Box, IconButton } from "@mui/material";

// import { data as collectedData } from "./makeData";
import {
    Button,
    ButtonGroup,
    Col,
    FormGroup,
    InputGroup,
    Row,
} from "react-bootstrap";

import { getCheckModal } from "../../utils/getModal";

// icons
import { MdNotInterested } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";

// components
import { Form, Modal } from "react-bootstrap";
import { Label } from "reactstrap";

import default_img from "../../assets/images/users/user5.jpg";
import { useForm } from "react-hook-form";

// cookies
import cookie from "react-cookies";

// apis
import API from "../../utils/API";
import axios from "axios";
import moment from "moment";
import getMessage from "../../utils/getMessage";

const countrys = [
    { id: 1, name: "السعوديه" },
    { id: 2, name: "الكويت" },
    { id: 3, name: "مصر" },
];

const Shaikhs = () => {
    const tableInstanceRef = useRef();
    const { register, handleSubmit, watch, reset, setValue } = useForm();

    const [countrys, setCountrys] = useState([]);
    const [preview, setPreview] = useState("");

    const [show, setShow] = useState({ isShow: false, id: 0 });
    const [data, setData] = useState([]);

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [isBtnActive, setIsBtnActive] = useState(false);
    const [rowSelectionIdx, setRowSelectionIdx] = useState({});

    const openModal = (id) => {
        setShow({ isShow: true, id });
    };
    const closeModal = () => {
        setShow({ isShow: false, id: 0 });
        reset({
            img: [],
            name: "",
            email: "",
            mobile: "",
            country_id: "",
            password: "",
            confirm_password: "",
            brief: "",
        });
    };
    const formatPhoneNumber = (phoneNumber) => {
        // Remove all non-digit characters from the input
        const cleaned = ("" + phoneNumber).replace(/\D/g, "");

        // Format the phone number as desired (e.g., add parentheses and dashes)
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }

        // Return the original input if it doesn't match the expected format
        return phoneNumber;
    };
    const columns = useMemo(
        () => [
            {
                accessorKey: "name",
                id: "name",
                header: "الاسم",
                size: 250,
                Cell: ({ row }) => (
                    <Box className="text-end d-flex align-items-center">
                        <img
                            style={{ width: 30, height: 30 }}
                            src={row.original?.avatar}
                            alt=""
                        />

                        <span className="ms-2">{row.original?.name}</span>
                    </Box>
                ),
            },

            {
                accessorKey: "mopile",
                id: "mopile",
                header: "رقم الهاتف",
                size: 100,
                Cell: ({ row }) => (
                    <Box className="text-end" dir="ltr">
                        <span>{formatPhoneNumber(row.original?.mopile)}</span>
                    </Box>
                ),
            },
            {
                accessorKey: "email",
                id: "email",
                header: "البريد الالكتروني",
                size: 250,
                Cell: ({ row }) => (
                    <Box className="text-end">
                        <a
                            className="text-decoration-none"
                            style={{ color: "#007bff" }}
                            href={`mailto:${row.original?.email}`}
                        >
                            {row.original?.email}
                        </a>
                    </Box>
                ),
            },
            {
                accessorKey: "country",
                id: "country",
                header: "الدولة",
                size: 100,
                Cell: ({ row }) => (
                    <Box className="text-end">
                        <span>{row.original?.country}</span>
                    </Box>
                ),
            },
            {
                accessorKey: "status",
                id: "status",
                header: "الحالة",
                size: 50,
                Cell: ({ row }) => (
                    <Box
                        className={`w-fit text-center px-2 py-1 rounded-3 m-auto ${
                            row.original?.status === "فعال"
                                ? "bg-movee"
                                : "bg-danger"
                        }`}
                    >
                        <span className="text-white">
                            {row.original?.status}
                        </span>
                    </Box>
                ),
            },

            {
                id: "createdAt",
                header: "انشأ بتاريخ",
                Cell: ({ row }) => (
                    <Box className="text-end">
                        <span>{moment(row.original?.createdAt).fromNow()}</span>
                    </Box>
                ),
            },
            {
                id: "actions",
                header: "العمليات",
                Cell: ({ row }) => (
                    <Box className="text-end">
                        <IconButton onClick={() => openModal(row.original.id)}>
                            <i className="bi bi-pencil-square fs-5"></i>
                        </IconButton>
                    </Box>
                ),
            },
        ],
        []
    );

    const getRowsIds = useCallback(() => {
        const rowSelectionIds = Object.keys(rowSelectionIdx || {}).map(
            (idx) => {
                return data[idx]?.id;
            }
        );

        return rowSelectionIds;
    }, [data, rowSelectionIdx]);
    const saveOrder = async () => {
        const token = cookie.load("token");
        const dataWithSeq = data?.map((item, idx) => ({
            ...item,
            seq: idx + 1,
        }));
        console.log(dataWithSeq);
        try {
            await API.post(`/admin/reorderData?token=${token}`, {
                table_name: "users",
                data: dataWithSeq,
            });
            getMessage("success", "تم حفظ الترتيب");

            getData();
        } catch (error) {
            console.error("Error saving order:", error);
            getMessage("error", "حدث خطأ أثناء حفظ الترتيب");
        } finally {
            closeModal();
        }
    };
    const getData = () => {
        const token = cookie.load("token");

        API(`admin/shaikhs?token=${token}`).then(({ data }) => {
            const collectedData = data?.Data?.map((shaikh) => ({
                id: shaikh?.id,
                name: shaikh?.name,
                country: shaikh?.country?.name,
                avatar: shaikh?.image,
                mopile: shaikh?.mobile,
                email: shaikh?.email,
                sex: shaikh?.gender === "1" ? "ذكر" : "انثي",
                status: shaikh?.status === "active" ? "فعال" : "غير فعال",
                ban: shaikh?.banned ? "محظور" : "حر",
                createdAt: shaikh?.created_at,
                brief: shaikh?.brief,
                seq: shaikh?.seq, // Include the seq attribute in the mapped object
            }));

            // Sort the collectedData array based on the 'seq' value
            collectedData.sort((a, b) => a.seq - b.seq);

            setData(collectedData);
        });
    };
    const deleteRows = () => {
        const rowsIds = getRowsIds();

        if (!rowsIds?.length) {
            alert("لم تحدد اي عنصر");
        }

        const deleteCallBack = async () => {
            const formData = new FormData();
            const token = cookie.load("token");

            formData.append("token", token);
            formData.append("id", rowsIds);

            await API.post(`/admin/users/delete`, formData);
            getData();
            setRowSelectionIdx({});
        };

        getCheckModal({
            title: "هل انت متاكد من انك تريد حذف المشايخ المحدده",
            type: "warning",
            confirmButtonText: "نعم حذف",
            isConfirmedMsg: "تم الحذف بنجاح",
            cb: deleteCallBack,
        });
    };
    const activeSelection = () => {
        const rowsIds = getRowsIds();

        if (!rowsIds?.length) {
            alert("لم تحدد اي عنصر");
        }

        const deleteCallBack = async () => {
            const formData = new FormData();
            const token = cookie.load("token");

            formData.append("token", token);
            formData.append("id", rowsIds);
            formData.append("event", "active");

            await API.post(`/admin/users/changeStatus`, formData);
            getData();
            setRowSelectionIdx({});
        };

        getCheckModal({
            title: "هل انت متاكد من انك تريد تفعيل المشايخ المحددين",
            type: "warning",
            confirmButtonText: "نعم تفعيل",
            isConfirmedMsg: "تم التفعيل بنجاح",
            cb: deleteCallBack,
        });
    };
    const disActiveSelection = () => {
        const rowsIds = getRowsIds();

        if (!rowsIds?.length) {
            alert("لم تحدد اي عنصر");
        }

        const deleteCallBack = async () => {
            const formData = new FormData();
            const token = cookie.load("token");

            formData.append("token", token);
            formData.append("id", rowsIds);
            formData.append("event", "not_active");

            await API.post(`/admin/users/changeStatus`, formData);
            getData();
            setRowSelectionIdx({});
        };

        getCheckModal({
            title: "هل انت متاكد من انك تريد تعطيل المشايخ المحددين",
            type: "warning",
            confirmButtonText: "نعم تعطيل",
            isConfirmedMsg: "تم التعطيل بنجاح",
            cb: deleteCallBack,
        });
    };
    const saveHandelar = async (data) => {
        const formData = new FormData();
        const token = cookie.load("token");
        data = { ...data, image: data?.img?.[0] };

        formData.append("token", token);
        show?.id && formData.append("id", show?.id);
        Object.keys(data)?.forEach((key) => {
            if (key === "img") return;

            data[key] && formData.append(key, data[key]);
        });

        await API.post(
            show?.id ? `/admin/shaikhs/update` : "/admin/shaikhs/create",
            formData
        )
            .then(({ data }) => {
                if (data?.code != 200) {
                    return getMessage("error", "يرجي ملي جميع الحقول");
                }

                getData();
                closeModal();
                reset({
                    img: [],
                    name: "",
                    email: "",
                    mobile: "",
                    country_id: "",
                    password: "",
                    confirm_password: "",
                    brief: "",
                });
                setPreview("");
            })
            .catch(() => {
                getMessage("error", "يرجي ملي جميع الحقول");
            });
    };
    const openFile = async (file) => {
        return new Promise((res) => {
            const reader = new FileReader();
            reader.onload = ({ target }) => {
                res(target?.result);
            };
            reader.readAsDataURL(file);
        });
    };

    useEffect(() => {
        const rowsIds = getRowsIds();

        if (rowsIds?.length) {
            setIsBtnActive(true);
        } else {
            setIsBtnActive(false);
        }
    }, [getRowsIds, rowSelectionIdx]);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        const getData = async () => {
            if (watch("img")?.length) {
                const data = await openFile(watch("img")[0]);

                setPreview((prev) => {
                    return prev === data ? prev : data;
                });
            } else {
                setPreview("");
            }
        };

        getData();
    });

    // get user data
    useEffect(() => {
        if (show?.id) {
            const currentUser = data?.find((user) => user.id === show.id);
            const countryId = countrys?.find(
                (country) => country.name === currentUser.country
            )?.id;

            setValue("name", currentUser?.name);
            setValue("email", currentUser?.email);
            setValue("mobile", currentUser?.mopile);
            setValue("country_id", countryId?.toString());
            setValue("brief", currentUser?.brief);

            axios
                .get(currentUser?.avatar, { responseType: "blob" })
                .then((res) => {
                    const blob = res.data;
                    const file = new File([blob], "image.jpg", {
                        type: "image/jpeg",
                    });
                    setValue("img", [file]);
                });
        }
    }, [data, show?.id, setValue, countrys]);

    // get countrys
    useEffect(() => {
        const token = cookie.load("token");

        API(`admin/countries?token=${token}`).then(({ data }) => {
            setCountrys(
                data?.data?.map((country) => ({
                    id: country.id,
                    name: country.name,
                }))
            );
        });
    }, []);

    return (
        <FullLayout
            title="المشايخ"
            renderButtons={() => (
                <div className="flex-center">
                    <ButtonGroup className="ms-4">
                        <Button
                            onClick={activeSelection}
                            disabled={!isBtnActive}
                            variant="primary"
                            className="flex-center text-white"
                        >
                            <i className="bi bi-check2 fs-5 flex-center" />
                            <span className="ms-1">فعال</span>
                        </Button>

                        <Button
                            onClick={disActiveSelection}
                            disabled={!isBtnActive}
                            variant="warning"
                            className="flex-center text-white"
                        >
                            <MdNotInterested size={20} />
                            <span className="ms-1">غير فعال</span>
                        </Button>

                        <Button
                            onClick={deleteRows}
                            disabled={!isBtnActive}
                            variant="danger"
                            className="flex-center text-white"
                        >
                            <i className="bi bi-trash3" />
                            <span className="ms-1">حذف</span>
                        </Button>
                    </ButtonGroup>
                    <Button
                        onClick={saveOrder}
                        variant="primary"
                        className="flex-center text-white ms-4"
                    >
                        <AiOutlinePlus size={20} />
                        <span className="ms-1">حفظ الترتيب</span>
                    </Button>
                    <Button
                        onClick={() => openModal(0)}
                        variant="primary"
                        className="flex-center text-white"
                    >
                        <AiOutlinePlus size={20} />
                        <span className="ms-1">اضافه</span>
                    </Button>
                </div>
            )}
        >
            <div style={{ maxHeight: 800, maxWidth: 1200, overflow: "auto" }}>
                <MaterialReactTable
                    enableRowOrdering
                    enableSorting={false}
                    enablePagination={false}
                    pagination={false}
                    onRowSelectionChange={setRowSelectionIdx}
                    columns={columns}
                    data={data}
                    state={{ rowSelection: rowSelectionIdx }}
                    initialState={{ showColumnFilters: true }}
                    enableFullScreenToggle={false}
                    enableDensityToggle={false}
                    enableColumnActions={false}
                    enableBottomToolbar={false}
                    enableColumnOrdering
                    enableGrouping
                    enablePinning
                    enableRowSelection
                    positionToolbarAlertBanner="bottom"
                    tableInstanceRef={tableInstanceRef}
                    muiTableBodyRowDragHandleProps={({ table }) => ({
                        onDragEnd: () => {
                            const { draggingRow, hoveredRow } =
                                table.getState();
                            if (hoveredRow && draggingRow) {
                                data.splice(
                                    hoveredRow.index,
                                    0,
                                    data.splice(draggingRow.index, 1)[0]
                                );
                                setData([...data]);
                            }
                        },
                    })}
                />
            </div>

            {/* Edit Modal or Add */}
            <Modal centered show={show.isShow} onHide={closeModal}>
                <Modal.Header>
                    <Modal.Title>تعديل او اضافة شيخ</Modal.Title>

                    <button
                        className="btn-close me-auto ms-0"
                        onClick={closeModal}
                    />
                </Modal.Header>
                <Modal.Body>
                    <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                    >
                        <Row className="mb-5">
                            <Label>تغيير الصوره</Label>

                            <div className="imgPlaceholder flex-center">
                                <label
                                    htmlFor="img"
                                    style={{
                                        width: 100,
                                        height: 100,
                                        cursor: "pointer",
                                        borderRadius: "50%",
                                        overflow: "hidden",
                                    }}
                                >
                                    <img
                                        className="w-100 h-100"
                                        src={preview || default_img}
                                        alt=""
                                    />
                                </label>
                            </div>

                            <input
                                type="file"
                                name="img"
                                id="img"
                                hidden
                                {...register("img")}
                            />
                        </Row>
                    </Form.Group>

                    <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                    >
                        <Row className="mb-3">
                            <Col lg="6">
                                <FormGroup>
                                    <Label for="name">الاسم</Label>
                                    <Form.Control
                                        id="name"
                                        type="text"
                                        {...register("name")}
                                    />
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup>
                                    <Label for="email">البريد الالكتروني</Label>
                                    <Form.Control
                                        id="email"
                                        type="text"
                                        {...register("email")}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                    >
                        <Row className="mb-3">
                            <Col lg="6">
                                <FormGroup>
                                    <Label for="mobile">موبايل</Label>
                                    <Form.Control
                                        id="mobile"
                                        type="text"
                                        {...register("mobile")}
                                    />
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup>
                                    <Label for="country_id">الدولة</Label>
                                    <Form.Control
                                        as="select"
                                        id="country_id"
                                        {...register("country_id")}
                                    >
                                        {countrys?.map((country, idx) => (
                                            <option
                                                key={idx}
                                                value={country?.id}
                                            >
                                                {country?.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form.Group>

                    {!show?.id && (
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Row className="mb-3">
                                <Col lg="6">
                                    <FormGroup>
                                        <Label for="password">
                                            كلمة المرور
                                        </Label>
                                        <InputGroup>
                                            <Form.Control
                                                id="password"
                                                type={
                                                    showPassword
                                                        ? "text"
                                                        : "password"
                                                }
                                                {...register("password")}
                                            />
                                            <Button
                                                variant="outline-secondary"
                                                onClick={() =>
                                                    setShowPassword(
                                                        !showPassword
                                                    )
                                                }
                                            >
                                                {showPassword ? (
                                                    <i className="bi bi-eye-slash"></i>
                                                ) : (
                                                    <i className="bi bi-eye"></i>
                                                )}
                                            </Button>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>

                                <Col lg="6">
                                    <FormGroup>
                                        <Label for="confirm_password">
                                            تاكيد كلمة المرور
                                        </Label>
                                        <InputGroup>
                                            <Form.Control
                                                id="confirm_password"
                                                type={
                                                    showConfirmPassword
                                                        ? "text"
                                                        : "password"
                                                }
                                                {...register(
                                                    "confirm_password"
                                                )}
                                            />
                                            <Button
                                                variant="outline-secondary"
                                                onClick={() =>
                                                    setShowConfirmPassword(
                                                        !showConfirmPassword
                                                    )
                                                }
                                            >
                                                {showConfirmPassword ? (
                                                    <i className="bi bi-eye-slash"></i>
                                                ) : (
                                                    <i className="bi bi-eye"></i>
                                                )}
                                            </Button>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form.Group>
                    )}

                    <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                    >
                        <Row className="mb-3">
                            <Col>
                                <FormGroup>
                                    <Label for="brief">نبذة مختصرة</Label>
                                    <Form.Control
                                        id="brief"
                                        as="textarea"
                                        rows={5}
                                        {...register("brief")}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className="flex-center">
                    <Button
                        variant="primary"
                        onClick={handleSubmit(saveHandelar)}
                    >
                        حفظ
                    </Button>

                    <Button variant="danger" onClick={closeModal}>
                        الغاء
                    </Button>
                </Modal.Footer>
            </Modal>
        </FullLayout>
    );
};

export default Shaikhs;
