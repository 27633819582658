import { Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";

// Pages
import Home from "./pages/Home";
import Admins from "./pages/Admins";
import Users from "./pages/Users";
import Shaikhs from "./pages/Shaikhs";
import References from "./pages/References";
import Banners from "./pages/Banners";
import Countries from "./pages/Countries";
import Faqs from "./pages/Faqs";
import PracticalMessages from "./pages/Practical_messages";
import Works from "./pages/Works";
import WorksRequests from "./pages/Works_requests";
import Contact from "./pages/Contact";
import Pages from "./pages/Pages";
import Notifications from "./pages/Notifications";
import Settings from "./pages/Settings";
import SystemSeo from "./pages/System_seo";
import EditMyProfile from "./pages/EditMyProfile";
import ChangeMyPassword from "./pages/ChangeMyPassword";
import SocialAccounts from "./pages/SocialAccounts";
import Login from "./pages/Login";

import Loading from "./components/Loading";
import UsersRequests from "./pages/Users_requests";
import API from "./utils/API";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// cookies
import cookie from "react-cookies";

import AdminData from "./utils/context/AdminData";

import "moment/locale/ar";

const App = () => {
  const [adminData, setAdminData] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    const token = cookie.load("token");

    if (!token) {
      return setIsLoading(true);
    }

    API(`/admin/infobytoken?token=${token}`)
      .then(({ data }) => {
        if (data?.code === 200) setIsLogin(true);
        setIsLoading(true);
        setAdminData(data?.Data);
      })
      .catch((err) => {
        setIsLoading(true);
      });
  }, []);

  return (
    <div className="App">
      <ToastContainer />

      <AdminData.Provider value={adminData}>
        {!isLoading ? (
          <Loading />
        ) : (
          <Routes>
            {isLoading && isLogin ? (
              <>
                <Route path="/" element={<Home />} />
                <Route path="/admins" element={<Admins />} />
                <Route path="/users" element={<Users />} />
                <Route path="/shaikhs" element={<Shaikhs />} />
                <Route path="/references" element={<References />} />
                <Route path="/banners" element={<Banners />} />
                <Route path="/countries" element={<Countries />} />
                <Route path="/faqs" element={<Faqs />} />
                <Route path="/users_requests" element={<UsersRequests />} />
                <Route path="/social" element={<SocialAccounts />} />
                <Route
                  path="/practical_messages"
                  element={<PracticalMessages />}
                />
                <Route path="/works" element={<Works />} />
                <Route path="/works_requests" element={<WorksRequests />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/pages" element={<Pages />} />
                <Route path="/notifications" element={<Notifications />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/system_seo" element={<SystemSeo />} />
                <Route path="/editMyProfile" element={<EditMyProfile />} />
                <Route
                  path="/changeMyPassword"
                  element={<ChangeMyPassword />}
                />
                <Route path="*" element={<Navigate to="/" replace />} />
              </>
            ) : isLoading && !isLogin ? (
              <>
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<Navigate to="/login" replace />} />
              </>
            ) : (
              ""
            )}
          </Routes>
        )}
      </AdminData.Provider>
    </div>
  );
};

export default App;
