import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// layout
import FullLayout from "../../layouts/FullLayout";

//MRT Imports
import MaterialReactTable from "material-react-table";

//Material-UI Imports
import { Box } from "@mui/material";

import { getCheckModal } from "../../utils/getModal";

// components
import { Button, ButtonGroup } from "react-bootstrap";

// import { data as collectedData } from "./makeData";
import moment from "moment";

const Works_requests = () => {
  const [data, setData] = useState([]);
  const [isBtnActive, setIsBtnActive] = useState(false);
  const [rowSelectionIdx, setRowSelectionIdx] = useState({});
  const tableInstanceRef = useRef();

  const columns = useMemo(
    () => [
      {
        accessorKey: "description",
        id: "description",
        header: "التفاصيل",
        size: 250,
        Cell: ({ row }) => (
          <Box className="text-end d-flex align-items-center">
            <span>{row.original?.description}</span>
          </Box>
        ),
      },
      {
        accessorKey: "type",
        id: "type",
        header: "النوع",
        size: 100,
        Cell: ({ row }) => (
          <Box className="text-end d-flex align-items-center">
            <span>{row.original?.type}</span>
          </Box>
        ),
      },
      {
        accessorKey: "month",
        id: "month",
        header: "شهر",
        size: 100,
        Cell: ({ row }) => (
          <Box className="text-end d-flex align-items-center">
            <span>{row.original?.month}</span>
          </Box>
        ),
      },
      {
        accessorKey: "isNum",
        id: "isNum",
        header: "له عدد",
        size: 100,
        Cell: ({ row }) => (
          <Box className="text-end d-flex align-items-center">
            <span>{row.original?.isNum}</span>
          </Box>
        ),
      },
      {
        accessorKey: "maximum",
        id: "maximum",
        header: "	الحد الاعلى للعد",
        size: 100,
        Cell: ({ row }) => (
          <Box className="text-end d-flex align-items-center">
            <span>{row.original?.maximum}</span>
          </Box>
        ),
      },
      {
        accessorKey: "status",
        id: "status",
        header: "الحالة",
        size: 50,
        Cell: ({ row }) => (
          <Box
            className={`w-fit text-center px-2 py-1 rounded-3 m-auto ${
              row.original?.status === "فعال" ? "bg-movee" : "bg-danger"
            }`}
          >
            <span className="text-white">{row.original?.status}</span>
          </Box>
        ),
      },
      {
        id: "createdAt",
        header: "انشأ بتاريخ",
        Cell: ({ row }) => (
          <Box className="text-end">
            <span>{moment(row.original?.createdAt).fromNow()}</span>
          </Box>
        ),
      },
    ],
    []
  );
  const getRowsIds = useCallback(() => {
    const rowSelectionIds = Object.keys(rowSelectionIdx || {}).map((idx) => {
      return data[idx]?.id;
    });

    return rowSelectionIds;
  }, [data, rowSelectionIdx]);
  const activeSelection = () => {
    const rowsIds = getRowsIds();

    if (!rowsIds?.length) {
      alert("لم تحدد اي عنصر");
    }

    const deleteCallBack = async () => {
      console.log(rowsIds);
    };

    getCheckModal({
      title: "هل انت متاكد من انك تريد تفعيل الأعمال المحددين",
      type: "warning",
      confirmButtonText: "نعم تفعيل",
      isConfirmedMsg: "تم التفعيل بنجاح",
      cb: deleteCallBack,
    });
  };

  useEffect(() => {
    const rowsIds = getRowsIds();

    if (rowsIds?.length) {
      setIsBtnActive(true);
    } else {
      setIsBtnActive(false);
    }
  }, [getRowsIds, rowSelectionIdx]);

  return (
    <FullLayout
      title="طلبات اضافة أعمال"
      renderButtons={() => (
        <div className="flex-center">
          <ButtonGroup className="ms-4">
            <Button
              onClick={activeSelection}
              disabled={!isBtnActive}
              variant="primary"
              className="flex-center text-white"
            >
              <i className="bi bi-check2 fs-5 flex-center" />
              <span className="ms-1">فعال</span>
            </Button>
          </ButtonGroup>
        </div>
      )}
    >
      <div style={{ maxHeight: 800, maxWidth: 1200, overflow: "auto" }}>
        <MaterialReactTable
          pagination={false}
          enablePagination={false}
          onRowSelectionChange={setRowSelectionIdx}
          columns={columns}
          data={data}
          state={{ rowSelection: rowSelectionIdx }}
          initialState={{ showColumnFilters: true }}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          enableColumnActions={false}
          enableBottomToolbar={false}
          enableColumnOrdering
          enableGrouping
          enablePinning
          enableRowSelection
          positionToolbarAlertBanner="bottom"
          tableInstanceRef={tableInstanceRef}
          renderDetailPanel={({ row }) => (
            <Box className="text-end">
              <div className="data d-flex gap-2">
                <p className="fs-5">التفاصيل:</p>

                <span style={{ whiteSpace: "pre-wrap" }} className="fs-5">
                  {row.original.description}
                </span>
              </div>
              <div className="data d-flex gap-2">
                <p className="fs-5">الثواب:</p>

                <span style={{ whiteSpace: "pre-wrap" }} className="fs-5">
                  {row.original.reward}
                </span>
              </div>
              <div className="data d-flex gap-2">
                <p className="fs-5">النوع:</p>

                <span style={{ whiteSpace: "pre-wrap" }} className="fs-5">
                  {row.original.type}
                </span>
              </div>
              <div className="data d-flex gap-2">
                <p className="fs-5">شهر:</p>

                <span style={{ whiteSpace: "pre-wrap" }} className="fs-5">
                  {row.original.month}
                </span>
              </div>
              <div className="data d-flex gap-2">
                <p className="fs-5">له عدد:</p>

                <span style={{ whiteSpace: "pre-wrap" }} className="fs-5">
                  {row.original.isNum}
                </span>
              </div>
              <div className="data d-flex gap-2">
                <p className="fs-5">الحد الاعلى للعد:</p>

                <span style={{ whiteSpace: "pre-wrap" }} className="fs-5">
                  {row.original.maximum}
                </span>
              </div>{" "}
              <div className="data d-flex gap-2">
                <p className="fs-5">المصدر:</p>

                <span style={{ whiteSpace: "pre-wrap" }} className="fs-5">
                  {row.original.resource}
                </span>
              </div>{" "}
              <div className="data d-flex gap-2">
                <p className="fs-5">شيخ:</p>

                <span style={{ whiteSpace: "pre-wrap" }} className="fs-5">
                  {row.original.sheikh}
                </span>
              </div>
            </Box>
          )}
        />
      </div>
    </FullLayout>
  );
};

export default Works_requests;
