import React from "react";

// layout
import FullLayout from "../../layouts/FullLayout";

import FaqsItems from "./FaqsItems";
import ReferencesSections from "../References/ReferencesSections";
// import FaqsSections from "./FaqsSections";

const Faqs = () => {
  return (
    <FullLayout title="الأسئلة الشائعة">
      {/* <FaqsSections /> */}
      <ReferencesSections />
      <FaqsItems />
    </FullLayout>
  );
};

export default Faqs;
