import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import FullLayout from "../../layouts/FullLayout";

//MRT Imports
import MaterialReactTable from "material-react-table";

//Material-UI Imports
import {
    Box,
    Checkbox,
    IconButton,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
} from "@mui/material";
import { Button, ButtonGroup, Col, FormGroup, Row } from "react-bootstrap";

// import { data as collectedData } from "./makeData";
import { getCheckModal } from "../../utils/getModal";

// icons
import { MdNotInterested } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";
import { Form, Modal } from "react-bootstrap";
import { Label } from "reactstrap";
import default_img from "../../assets/images/users/user5.jpg";

// forms
import { useForm } from "react-hook-form";

// apis
import API from "../../utils/API";
import axios from "axios";

// cookies
import cookie from "react-cookies";

import moment from "moment";
import getMessage from "../../utils/getMessage";

const SocialAccounts = () => {
    const { register, handleSubmit, watch, setValue, reset } = useForm();

    const [preview, setPreview] = useState("");
    const [show, setShow] = useState({ isShow: false, id: 0 });
    const [data, setData] = useState([]);
    const [isBtnActive, setIsBtnActive] = useState(false);
    const [rowSelectionIdx, setRowSelectionIdx] = useState({});
    const tableInstanceRef = useRef();

    const openModal = (id) => {
        setShow({ isShow: true, id });
    };
    const closeModal = () => {
        setShow({ isShow: false, id: 0 });

        reset({
            icon: [],
            name: "",
            url: "",
        });
    };
    const columns = useMemo(
        () => [
            {
                accessorKey: "name",
                id: "name",
                header: "الاسم",
                size: 250,
                Cell: ({ row }) => (
                    <Box className="text-end d-flex align-items-center">
                        <img
                            style={{ width: 25, height: 25 }}
                            src={row.original?.icon}
                            alt=""
                        />

                        <span className="ms-2">{row.original?.name}</span>
                    </Box>
                ),
            },
            {
                accessorKey: "url",
                id: "url",
                header: "الرابط",
                size: 200,
                Cell: ({ row }) => (
                    <Box
                        style={{
                            maxWidth: 200,
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                        }}
                        className="text-end"
                    >
                        <a
                            className="text-decoration-none"
                            style={{ color: "#007bff" }}
                            href={row.original?.url}
                        >
                            {row.original?.url}
                        </a>
                    </Box>
                ),
            },
            {
                accessorKey: "status",
                id: "status",
                header: "الحالة",
                Cell: ({ row }) => (
                    <Box
                        className={`w-fit text-center px-2 py-1 rounded-3 m-auto ${
                            row.original?.status === "فعال"
                                ? "bg-movee"
                                : "bg-danger"
                        }`}
                    >
                        <span className="text-white">
                            {row.original?.status}
                        </span>
                    </Box>
                ),
            },
            {
                id: "createdAt",
                header: "انشأ بتاريخ",
                Cell: ({ row }) => (
                    <Box className="text-end">
                        <span>{moment(row.original?.createdAt).fromNow()}</span>
                    </Box>
                ),
            },
            {
                id: "actions",
                header: "العمليات",
                Cell: ({ row }) => (
                    <Box className="text-end">
                        <IconButton onClick={() => openModal(row.original.id)}>
                            <i className="bi bi-pencil-square fs-5"></i>
                        </IconButton>
                    </Box>
                ),
            },
        ],
        []
    );

    const getData = () => {
        API(`admin/socialmedia`).then(({ data }) => {
            const collectedData = data?.Data?.map((social) => ({
                id: social?.id,
                icon: social?.logo,
                name: social?.name,
                url: social?.url,
                status: social?.status === "active" ? "فعال" : "غير فعال",
                createdAt: social?.created_at,
                seq: social?.seq, // Include the seq attribute in the mapped object
            }));

            // Sort the collectedData array based on the 'seq' value
            collectedData.sort((a, b) => a.seq - b.seq);

            setData(collectedData || []);
        });
    };
    const getRowsIds = useCallback(() => {
        const rowSelectionIds = Object.keys(rowSelectionIdx || {}).map(
            (idx) => {
                return data[idx]?.id;
            }
        );

        return rowSelectionIds;
    }, [data, rowSelectionIdx]);
    const saveOrder = async () => {
        const token = cookie.load("token");
        const dataWithSeq = data?.map((item, idx) => ({
            ...item,
            seq: idx + 1,
        }));
        console.log(dataWithSeq);
        try {
            await API.post(`/admin/reorderData?token=${token}`, {
                table_name: "social_media",
                data: dataWithSeq,
            });
            getMessage("success", "تم حفظ الترتيب");

            getData();
        } catch (error) {
            console.error("Error saving order:", error);
            getMessage("error", "حدث خطأ أثناء حفظ الترتيب");
        } finally {
            closeModal();
        }
    };
    const deleteRows = () => {
        const rowsIds = getRowsIds();

        if (!rowsIds?.length) {
            alert("لم تحدد اي عنصر");
        }

        const deleteCallBack = async () => {
            const formData = new FormData();
            const token = cookie.load("token");

            formData.append("token", token);
            formData.append("id", rowsIds);
            formData.append("event", "delete");

            await API.post(`/admin/admins/SocialMediaStatus`, formData);
            getData();
            setRowSelectionIdx({});
        };

        getCheckModal({
            title: "هل انت متاكد من انك تريد حذف الحسابات المحدده",
            type: "warning",
            confirmButtonText: "نعم حذف",
            isConfirmedMsg: "تم الحذف بنجاح",
            cb: deleteCallBack,
        });
    };
    const activeSelection = () => {
        const rowsIds = getRowsIds();

        if (!rowsIds?.length) {
            alert("لم تحدد اي عنصر");
        }

        const deleteCallBack = async () => {
            const formData = new FormData();
            const token = cookie.load("token");

            formData.append("token", token);
            formData.append("id", rowsIds);
            formData.append("event", "active");

            await API.post(`/admin/admins/SocialMediaStatus`, formData);
            getData();
            setRowSelectionIdx({});
        };

        getCheckModal({
            title: "هل انت متاكد من انك تريد تفعيل الحسابات المحددين",
            type: "warning",
            confirmButtonText: "نعم تفعيل",
            isConfirmedMsg: "تم التفعيل بنجاح",
            cb: deleteCallBack,
        });
    };
    const disActiveSelection = () => {
        const rowsIds = getRowsIds();

        if (!rowsIds?.length) {
            alert("لم تحدد اي عنصر");
        }

        const deleteCallBack = async () => {
            const formData = new FormData();
            const token = cookie.load("token");

            formData.append("token", token);
            formData.append("id", rowsIds);
            formData.append("event", "not_active");

            await API.post(`/admin/admins/SocialMediaStatus`, formData);
            getData();
            setRowSelectionIdx({});
        };

        getCheckModal({
            title: "هل انت متاكد من انك تريد تعطيل الحسابات المحددين",
            type: "warning",
            confirmButtonText: "نعم تعطيل",
            isConfirmedMsg: "تم التعطيل بنجاح",
            cb: deleteCallBack,
        });
    };
    const saveHandelar = async (data) => {
        const token = cookie.load("token");
        const formData = new FormData();

        show?.id && formData.append("id", show?.id);
        data?.icon?.[0] && formData.append("logo", data?.icon?.[0]);
        formData.append("token", token);
        formData.append("name", data?.name);
        formData.append("url", data?.url);

        await API.post(
            show?.id ? `/admin/EditSocial` : "admin/StoreSocial",
            formData
        )
            .then(({ data }) => {
                if (data?.code != 200) {
                    getMessage("error", "يرجي ملي جميع الحقول");
                }
            })
            .catch(() => {
                getMessage("error", "يرجي ملي جميع الحقول");
            });
        getData();
        closeModal();
    };
    const openFile = async (file) => {
        return new Promise((res) => {
            const reader = new FileReader();
            reader.onload = ({ target }) => {
                res(target?.result);
            };
            reader.readAsDataURL(file);
        });
    };

    useEffect(() => {
        const rowsIds = getRowsIds();

        if (rowsIds?.length) {
            setIsBtnActive(true);
        } else {
            setIsBtnActive(false);
        }
    }, [getRowsIds, rowSelectionIdx]);

    useEffect(() => {
        const getData = async () => {
            if (watch("icon")?.length) {
                const data = await openFile(watch("icon")[0]);

                setPreview((prev) => {
                    return prev === data ? prev : data;
                });
            } else {
                setPreview("");
            }
        };

        getData();
    });

    useEffect(() => {
        getData();
    }, []);

    // get banner data
    useEffect(() => {
        if (show?.id) {
            const currentAccount = data?.find((acc) => acc.id === show.id);

            setValue("name", currentAccount?.name);
            setValue("url", currentAccount?.url);
            axios
                .get(currentAccount?.icon, { responseType: "blob" })
                .then((res) => {
                    const blob = res.data;
                    const file = new File([blob], "image.jpg", {
                        type: "image/jpeg",
                    });
                    setValue("icon", [file]);
                });
        }
    }, [data, show.id, setValue]);

    return (
        <FullLayout
            title="حسابات التواصل"
            renderButtons={() => (
                <div className="flex-center">
                    <ButtonGroup className="ms-4">
                        <Button
                            onClick={activeSelection}
                            disabled={!isBtnActive}
                            variant="primary"
                            className="flex-center text-white"
                        >
                            <i className="bi bi-check2 fs-5 flex-center" />
                            <span className="ms-1">فعال</span>
                        </Button>

                        <Button
                            onClick={disActiveSelection}
                            disabled={!isBtnActive}
                            variant="warning"
                            className="flex-center text-white"
                        >
                            <MdNotInterested size={20} />
                            <span className="ms-1">غير فعال</span>
                        </Button>

                        <Button
                            onClick={deleteRows}
                            disabled={!isBtnActive}
                            variant="danger"
                            className="flex-center text-white"
                        >
                            <i className="bi bi-trash3" />
                            <span className="ms-1">حذف</span>
                        </Button>
                    </ButtonGroup>
                    <Button
                        onClick={saveOrder}
                        variant="primary"
                        className="flex-center text-white ms-4"
                    >
                        <AiOutlinePlus size={20} />
                        <span className="ms-1">حفظ الترتيب</span>
                    </Button>
                    <Button
                        onClick={() => openModal(0)}
                        variant="primary"
                        className="flex-center text-white"
                    >
                        <AiOutlinePlus size={20} />
                        <span className="ms-1">اضافه</span>
                    </Button>
                </div>
            )}
        >
            <div style={{ maxHeight: 800, maxWidth: 1200, overflow: "auto" }}>
                <MaterialReactTable
                    enableRowOrdering
                    enableSorting={false}
                    enablePagination={false}
                    pagination={false}
                    onRowSelectionChange={setRowSelectionIdx}
                    columns={columns}
                    data={data}
                    state={{ rowSelection: rowSelectionIdx }}
                    initialState={{ showColumnFilters: true }}
                    enableFullScreenToggle={false}
                    enableDensityToggle={false}
                    enableColumnActions={false}
                    enableBottomToolbar={false}
                    enableColumnOrdering
                    enableGrouping
                    enablePinning
                    enableRowSelection
                    positionToolbarAlertBanner="bottom"
                    tableInstanceRef={tableInstanceRef}
                    muiTableBodyRowDragHandleProps={({ table }) => ({
                        onDragEnd: () => {
                            const { draggingRow, hoveredRow } =
                                table.getState();
                            if (hoveredRow && draggingRow) {
                                data.splice(
                                    hoveredRow.index,
                                    0,
                                    data.splice(draggingRow.index, 1)[0]
                                );
                                setData([...data]);
                            }
                        },
                    })}
                />
            </div>

            {/* Edit Modal or Add */}
            <Modal centered show={show.isShow} onHide={closeModal}>
                <Modal.Header>
                    <Modal.Title>تعديل او اضافة حساب تواصل</Modal.Title>

                    <button
                        className="btn-close me-auto ms-0"
                        onClick={closeModal}
                    />
                </Modal.Header>
                <Modal.Body>
                    <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                    >
                        <Row className="mb-5">
                            <Label>الايقونه</Label>

                            <div className="imgPlaceholder flex-center">
                                <label
                                    htmlFor="icon"
                                    style={{
                                        width: 100,
                                        height: 100,
                                        cursor: "pointer",
                                        borderRadius: "50%",
                                        overflow: "hidden",
                                    }}
                                >
                                    <img
                                        className="w-100 h-100"
                                        src={preview || default_img}
                                        alt=""
                                    />
                                </label>
                            </div>

                            <input
                                type="file"
                                name="icon"
                                id="icon"
                                hidden
                                {...register("icon")}
                            />
                        </Row>
                    </Form.Group>

                    <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                    >
                        <Row className="mb-3">
                            <Col lg="6">
                                <FormGroup>
                                    <Label for="name">الاسم</Label>
                                    <Form.Control
                                        id="name"
                                        type="text"
                                        {...register("name")}
                                    />
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup>
                                    <Label for="email">الرابط</Label>
                                    <Form.Control
                                        id="url"
                                        type="text"
                                        {...register("url")}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className="flex-center">
                    <Button
                        onClick={handleSubmit(saveHandelar)}
                        variant="primary"
                    >
                        حفظ
                    </Button>

                    <Button variant="danger" onClick={closeModal}>
                        الغاء
                    </Button>
                </Modal.Footer>
            </Modal>
        </FullLayout>
    );
};

export default SocialAccounts;
