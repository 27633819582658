import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

//MRT Imports
import MaterialReactTable from "material-react-table";

//Material-UI Imports
import { Box } from "@mui/material";

//Mock Data
import { getCheckModal } from "../../../utils/getModal";

// request
import cookie from "react-cookies";
import API from "../../../utils/API";

import moment from "moment";
import { Button, ButtonGroup } from "react-bootstrap";
import { MdNotInterested } from "react-icons/md";
import getMessage from "../../../utils/getMessage";
import { AiOutlinePlus } from "react-icons/ai";

const QuestionsRequests = () => {
  const [data, setData] = useState([]);
  const [isBtnActive, setIsBtnActive] = useState(false);
  const [rowSelectionIdx, setRowSelectionIdx] = useState({});
  const tableInstanceRef = useRef();
  const columns = useMemo(
    () => [
      {
        id: "id",
        header: "#",
        size: 50,
        Cell: ({ row }) => (
          <Box className="text-end">
            <span>{row.original?.id}</span>
          </Box>
        ),
      },
      {
        accessorKey: "question",
        id: "question",
        header: "السؤال",
        size: 150,
        Cell: ({ row }) => (
          <Box className="text-end">
            <span>{row.original?.question}</span>
          </Box>
        ),
      },
      {
        accessorKey: "answer",
        id: "answer",
        header: "الإجابة",
        size: 200,
        Cell: ({ row }) => (
          <Box className="text-end">
            <span>{row.original?.answer}</span>
          </Box>
        ),
      },
      {
        accessorKey: "section",
        id: "section",
        header: "القسم",
        size: 100,
        Cell: ({ row }) => (
          <Box className="text-end">
            <span>{row.original?.section}</span>
          </Box>
        ),
      },
      {
        accessorKey: "ref",
        id: "ref",
        header: "المرجع",
        size: 100,
        Cell: ({ row }) => (
          <Box className="text-end">
            <span>{row.original?.ref}</span>
          </Box>
        ),
      },
      {
        accessorKey: "sheikh",
        id: "sheikh",
        header: "شيخ",
        size: 100,
        Cell: ({ row }) => (
          <Box className="text-end">
            <span>{row.original?.sheikh}</span>
          </Box>
        ),
      },
      {
        accessorKey: "status",
        id: "status",
        header: "الحالة",
        Cell: ({ row }) => (
          <Box
            className={`w-fit text-center px-2 py-1 rounded-3 m-auto ${
              row.original?.status === "مقبول" ? "bg-movee" : "bg-danger"
            }`}
          >
            <span className="text-white">{row.original?.status}</span>
          </Box>
        ),
      },
      {
        id: "createdAt", //id is still required when using accessorFn instead of accessorKey
        header: "انشأ بتاريخ",
        Cell: ({ row }) => (
          <Box className="text-end">
            <span>{moment(row.original?.createdAt).fromNow()}</span>
          </Box>
        ),
      },
    ],
    []
  );

  const getRowsIds = useCallback(() => {
    const rowSelectionIds = Object.keys(rowSelectionIdx || {}).map((idx) => {
      return data[idx]?.id;
    });

    return rowSelectionIds;
  }, [data, rowSelectionIdx]);
    const saveOrder = async () => {
      const token = cookie.load("token");
      const dataWithSeq = data?.map((item, idx) => ({ ...item, seq: idx + 1 }));
      console.log(dataWithSeq);
      try {
        await API.post(`/admin/reorderData?token=${token}`, {
          table_name: "faq_requests",
          data: dataWithSeq,
        });
        getMessage("success", "تم حفظ الترتيب");

        getData();
      } catch (error) {
        console.error("Error saving order:", error);
        getMessage("error", "حدث خطأ أثناء حفظ الترتيب");
      }
    };
  const deleteRows = () => {
    const rowsIds = getRowsIds();

    if (!rowsIds?.length) {
      alert("لم تحدد اي عنصر");
    }

    const deleteCallBack = async () => {
      const formData = new FormData();
      const token = cookie.load("token");

      formData.append("type", "request");
      formData.append("event", "delete");
      formData.append("token", token);
      formData.append("id", rowsIds);

      await API.post(`/admin/faqresources/changestatus`, formData);
      getData();
      setRowSelectionIdx({});
    };

    getCheckModal({
      title: "هل انت متاكد من انك تريد حذف الاسئله المحدده",
      type: "warning",
      confirmButtonText: "نعم حذف",
      isConfirmedMsg: "تم الحذف بنجاح",
      cb: deleteCallBack,
    });
  };

  const getData = () => {
    const token = cookie.load("token");

    API(`/admin/faq_requests?token=${token}`).then(({ data }) => {
      const collectData = data?.Data?.items?.map((item) => ({
        id: item?.id,
        question: item?.question,
        answer: item?.answer,
        section: item?.section_name || "قسم غير معروف",
        ref: item?.reference_name || "مرجع غير معروف",
        sheikh: item?.sheikh,
        status: item?.status === "new" ? "جديد" : "مقبول",
        source: item?.source,
        createdAt: item?.created_at,
        seq: item?.seq, // Include the seq attribute in the mapped object
      }));

      // Sort the collectedData array based on the 'seq' value
      collectData.sort((a, b) => a.seq - b.seq);
      setData(collectData);
    });
  };

  const activeSelection = () => {
    const rowsIds = getRowsIds();

    if (!rowsIds?.length) {
      alert("لم تحدد اي عنصر");
    }

    const callBackHandelar = async () => {
      const formData = new FormData();
      const token = cookie.load("token");

      formData.append("token", token);
      formData.append("type", "request");
      formData.append("id", rowsIds);
      formData.append("status", 1);
      formData.append("event", "approved");

      await API.post(`/admin/faqresources/changestatus`, formData);
      getData();
      setRowSelectionIdx({});
    };

    getCheckModal({
      title: "هل انت متاكد من انك تريد تفعيل العناصر المحدده",
      type: "warning",
      confirmButtonText: "نعم تفعيل",
      isConfirmedMsg: "تم التفعيل بنجاح",
      cb: callBackHandelar,
    });
  };
  const disActiveSelection = () => {
    const rowsIds = getRowsIds();

    if (!rowsIds?.length) {
      alert("لم تحدد اي عنصر");
    }

    const deleteCallBack = async () => {
      const formData = new FormData();
      const token = cookie.load("token");

      formData.append("token", token);
      formData.append("event", "new");
      formData.append("type", "request");
      formData.append("id", rowsIds);
      formData.append("status", "new");
      await API.post(`/admin/faqresources/changestatus`, formData);
      getData();
      setRowSelectionIdx({});
    };

    getCheckModal({
      title: "هل انت متاكد من انك تريد تعطيل المراجع المحددين",
      type: "warning",
      confirmButtonText: "نعم تعطيل",
      isConfirmedMsg: "تم التعطيل بنجاح",
      cb: deleteCallBack,
    });
  };
  useEffect(() => {
    const rowsIds = getRowsIds();

    if (rowsIds?.length) {
      setIsBtnActive(true);
    } else {
      setIsBtnActive(false);
    }
  }, [getRowsIds, rowSelectionIdx]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <article className="mb-5">
      <h2 className="mb-3">طلبات اضافة أسئلة</h2>

      <div style={{ maxHeight: 800, maxWidth: 1200, overflow: "auto" }}>
        <MaterialReactTable
          enableRowOrdering
          enableSorting={false}
          enablePagination={false}
          pagination={false}
          onRowSelectionChange={setRowSelectionIdx}
          columns={columns}
          data={data}
          state={{ rowSelection: rowSelectionIdx }}
          initialState={{ showColumnFilters: true }}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          enableColumnActions={false}
          enableBottomToolbar={false}
          enableColumnOrdering
          enableGrouping
          enablePinning
          enableRowSelection
          positionToolbarAlertBanner="bottom"
          tableInstanceRef={tableInstanceRef}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                data.splice(
                  hoveredRow.index,
                  0,
                  data.splice(draggingRow.index, 1)[0]
                );
                setData([...data]);
              }
            },
          })}
          renderDetailPanel={({ row }) => (
            <Box className="text-end">
              <div className="data d-flex gap-2">
                <p className="fs-5">السؤال:</p>

                <span style={{ whiteSpace: "pre-wrap" }} className="fs-5">
                  {row.original.question}
                </span>
              </div>

              <div className="data d-flex gap-2">
                <p className="fs-5">الإجابة:</p>

                <span style={{ whiteSpace: "pre-wrap" }} className="fs-5">
                  {row.original.answer}
                </span>
              </div>

              <div className="data d-flex gap-2">
                <p className="fs-5">المرجع:</p>

                <span style={{ whiteSpace: "pre-wrap" }} className="fs-5">
                  {row.original.ref}
                </span>
              </div>

              <div className="data d-flex gap-2">
                <p className="fs-5">القسم:</p>

                <span style={{ whiteSpace: "pre-wrap" }} className="fs-5">
                  {row.original.section}
                </span>
              </div>

              <div className="data d-flex gap-2">
                <p className="fs-5">المصدر:</p>

                <span style={{ whiteSpace: "pre-wrap" }} className="fs-5">
                  {row.original.source}
                </span>
              </div>

              <div className="data d-flex gap-2">
                <p className="fs-5">شيخ:</p>

                <span style={{ whiteSpace: "pre-wrap" }} className="fs-5">
                  {row.original.sheikh}
                </span>
              </div>
            </Box>
          )}
          renderTopToolbarCustomActions={() => {
            return (
              <ButtonGroup className="ms-4">
                <Button
                  onClick={saveOrder}
                  variant="primary"
                  className="flex-center mt-2 text-white"
                >
                  <AiOutlinePlus size={20} />
                  <span className="ms-1">حفظ الترتيب</span>
                </Button>
                <ButtonGroup className="ms-4">
                  <Button
                    onClick={activeSelection}
                    disabled={!isBtnActive}
                    variant="primary"
                    className="flex-center text-white"
                  >
                    <i className="bi bi-check2 fs-5 flex-center" />
                    <span className="ms-1">فعال</span>
                  </Button>

                  <Button
                    onClick={disActiveSelection}
                    disabled={!isBtnActive}
                    variant="warning"
                    className="flex-center text-white"
                  >
                    <MdNotInterested size={20} />
                    <span className="ms-1">غير فعال</span>
                  </Button>

                  <Button
                    onClick={deleteRows}
                    disabled={!isBtnActive}
                    variant="danger"
                    className="flex-center text-white"
                  >
                    <i className="bi bi-trash3" />
                    <span className="ms-1">حذف</span>
                  </Button>
                </ButtonGroup>
              </ButtonGroup>
            );
          }}
        />
      </div>
    </article>
  );
};

export default QuestionsRequests;
