import React, { useState } from "react";
import FullLayout from "../../layouts/FullLayout";

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  InputGroup,
  Label,
  Row,
} from "reactstrap";

import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";

// cookies
import cookie from "react-cookies";

// apis
import API from "../../utils/API";
import getMessage from "../../utils/getMessage";
import { Button } from "react-bootstrap";

const ChangeMyPassword = () => {
  const { register, handleSubmit } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const saveHandelar = (data) => {
    if (data?.password?.length > 20) {
      return alert("اكبر عدد حروف لكلمة السر هو 20");
    } else if (data?.password?.length < 8) {
      return alert("اقل عدد حروف لكلمة السر هو 8");
    } else if (data?.password != data?.re_password) {
      return alert("كلمة السر واعادة كلمة السر غير متطابقين");
    }

    const token = cookie.load("token");
    const formData = new FormData();

    formData.append("token", token);
    formData.append("password", data?.password);
    formData.append("confirm_password", data?.re_password);

    API.post(`/admin/updateMyPassword`, formData)
      .then(({ data }) => {
        if (data?.code != 200) {
          getMessage("error", "يرجي ملي جميع الحقول");
        }

        if (data.code == 200) {
          getMessage("success", "تم الحفظ");
        }
      })
      .catch(() => {
        getMessage("error", "يرجي ملي جميع الحقول");
      });
  };

  return (
    <FullLayout
      renderButtons={() => (
        <button
          onClick={handleSubmit(saveHandelar)}
          className="btn btn-primary"
        >
          حفظ
        </button>
      )}
      title="تعديل كلمة المرور"
    >
      <form onSubmit={handleSubmit(saveHandelar)}>
        <Card className="shadow-sm border">
          <CardTitle tag="h4" className="border-bottom text-dark p-3 mb-0">
            تعديل كلمة المرور
          </CardTitle>
          <CardBody>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <Label for="password">كلمة المرور</Label>
                  <InputGroup>
                    <Form.Control
                      id="password"
                      {...register("password")}
                      type={showPassword ? "text" : "password"}
                    />
                    <Button
                      variant="outline-secondary"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <i className="bi bi-eye-slash"></i>
                      ) : (
                        <i className="bi bi-eye"></i>
                      )}
                    </Button>
                  </InputGroup>
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <Label for="re_password">تاكيد كلمة المرور</Label>
                  <InputGroup>
                    <Form.Control
                      id="re_password"
                      {...register("re_password")}
                      type={showConfirmPassword ? "text" : "password"}
                    />
                    <Button
                      variant="outline-secondary"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? (
                        <i className="bi bi-eye-slash"></i>
                      ) : (
                        <i className="bi bi-eye"></i>
                      )}
                    </Button>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </form>
    </FullLayout>
  );
};

export default ChangeMyPassword;
